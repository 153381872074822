import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Terms = () => {
    const handleDownload = () => {
        // Assuming 'prd.pdf' is the path to your PDF file
        const pdfPath = '../../files/terms.pdf';

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = 'Cerohero_Terms&Conditions.pdf'; // Set the filename for the download

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link
        link.click();

        // Remove the link from the DOM
        document.body.removeChild(link);
    };
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

    }, []);

    return (
        <>
            <div className="terms-conditions">
                <div className="container">
                    <div className="terms-inside">
                        <h3 className="terms-title">Terms and Conditions</h3>
                        <p className="terms-para">Please carefully review these Terms of Service (the "Terms") and our Privacy Policy (the "Privacy Policy") as they govern your use of the website  <NavLink to="/landing" className="terms-website">www.cerohero.com </NavLink>  ("Site") and the mobile application (the "App") provided by Chakravuyha Software Technologies Pvt Ltd and GreenCred Tech Oy in Europe. The services, accessible via the Site and App, are designed to assist users in offsetting their carbon footprint through simple activities (specified in carbon dioxide equivalents) and allow users to track such activities. The Site, the App, and our services collectively are referred to as the "services" to facilitate a clearer understanding of these Terms. </p>
                        <p className="terms-para">Note that the terminology used may change in the future, and we reserve the right to periodically update these terms. It is advisable to review these terms each time you intend to use our site to ensure you are aware of the conditions in effect at the moment. Additionally, we may occasionally update and modify our website to reflect changes related to our goods, user demands, company goals, or legal requirements. </p>
                        <h4 className="terms-subtitle">1. Acceptance of the Terms </h4>
                        <p className="terms-para">You consent to being bound by these Terms by using our services. Do not use the services if you do not agree to be bound by these Terms. </p>
                        <h4 className="terms-subtitle">2. Privacy Principles </h4>
                        <p className="terms-para">For detailed information on the collection, use, and disclosure of your information, please refer to our Privacy Policy. The Privacy Policy also governs your usage of the services. </p>
                        <h4 className="terms-subtitle">3. Modifications to the Services or These Terms </h4>
                        <p className="terms-para">We reserve the right to periodically modify these Terms. In case of alterations, we will notify you by publishing the revised Terms on the website and mobile application or sending additional messages. It is crucial to revisit the Terms every time as changes may occur. Continued use of the services after modifications will be deemed as acceptance. We reserve the right to modify or terminate services without prior notice, for any reason. </p>
                        <h4 className="terms-subtitle">4. Who May Use the services?</h4>
                        <p className="terms-subpara">(a) Account Security and Eligibility</p>
                        <p className="terms-para">You may use the services if you are at least 14 years old, legally capable of entering into a binding contract with Cero Hero, and not prohibited by relevant law. Accurate and current information is necessary for your account, and you must keep it up to date. Failure to do so may result in suspension or closure of your account. You are responsible for all actions related to your account. </p>
                        <p className="terms-subpara">(b) Linking Accounts from Third Parties </p>
                        <p className="terms-para">To access certain service features, you may need to link third-party accounts. By doing so, you represent that you have the necessary authorizations for accessing and using such Third-Party accounts.  </p>
                        <h4 className="terms-subtitle">5. Response </h4>
                        <p className="terms-para">Your opinions, remarks, suggestions, and proposals for enhancing the services ("Feedback") are valued. By providing Feedback, you consent to its use by us without limitation or payment to you. </p>

                        <h4 className="terms-subtitle">6. Earning Cero Credits </h4>
                        <p className="terms-para">"Cero Credits" are units users generate through verified challenges, used to access benefits on the platform. These credits cannot be redeemed for cash but can be exchanged on the Cero Hero Marketplace. We reserve the right to void Cero Credits earned through unauthorized challenges and suspend or disable associated accounts. </p>
                        <p className="terms-para">We may void or redistribute Cero Credits acquired through fraudulent means, such as defrauding another user, and suspend or disable involved accounts. We retain discretion to determine eligible movement forms, verification algorithms, and verified movement amounts for generating Cero Credits. A cap on daily Cero Credits may be set, varying based on user criteria, and may change over time.  </p>
                        <h4 className="terms-subtitle">7. Cero Hero Marketplace </h4>
                        <p className="terms-para">Here, users can transform their hard-earned Cero Credits, earned through active participation in accepted challenges, into a variety of exciting products. The Cero Hero Marketplace offers a dynamic platform where Cero Credits can be converted and used to purchase items tailored to individual preferences. </p>

                        <h4 className="terms-subtitle">A. User-Centric Flexibility  </h4>
                        <p className="terms-para">In this marketplace, each product is meticulously assigned a specific value and price in Cero Credits. This unique structure provides users with the flexibility to choose and acquire items that resonate with their personal achievements and preferences. It's more than just a marketplace; it's a space for users to explore, celebrate accomplishments, and enjoy the benefits of their journey within the Cero Hero community.  </p>

                        <h4 className="terms-subtitle">B.  Redemption Codes and Third-Party Benefits </h4>
                        <p className="terms-para">The Cero Hero Marketplace introduces Redemption Codes – a key to unlocking Third-Party Benefits. These codes, available for purchase with Cero Credits, empower users to access discounts and offerings from various third parties, such as retailers and websites. </p>

                        <h4 className="terms-subtitle">C. Direct Engagement and User Agreement</h4>
                        <p className="terms-para">It's essential to understand that by spending your Cero Credits on the Cero Hero Marketplace to acquire Redemption Codes, you are entering into a direct contract with us. While this facilitates the acquisition of Third-Party Benefits, users must acknowledge that Cero Hero assumes no responsibility for any aspects related to these benefits.</p>

                        <h4 className="terms-subtitle">D. Quality Assurance Disclaimer</h4>
                        <p className="terms-para">Upon redeeming or using Redemption Codes for Third-Party Benefits, users directly engage with the respective third party. Cero Hero emphasizes that it does not control or guarantee the quality, safety, legality, or accuracy of Third-Party Benefits. Users acknowledge that they are responsible for interacting with third parties, and any such interaction occurs on third-party platforms beyond our control.</p>

                        <h4 className="terms-subtitle">E. Terms and Conditions</h4>
                        <p className="terms-para">The redemption or use of Redemption Codes is subject to specific terms stated as part of the Redemption Codes. Users are encouraged to review and comply with these terms to ensure a seamless experience within the marketplace.</p>

                        <h4 className="terms-subtitle">G. Cero Hero's Right to Modify</h4>
                        <p className="terms-para">Cero Hero reserves the right to modify the selection of available Redemption Codes, adjust the number of Cero Credits required for redemption, and limit the number of redemptions at its absolute discretion. Users should stay informed about any updates made to enhance their overall marketplace experience.</p>
                        <h4 className="terms-subtitle">8. Eco Courses </h4>
                        <p className="terms-para">Cero Hero provides a platform for some training courses and related services called “Eco Courses”. Cero Hero is committed to promoting environmental education and awareness. As part of this commitment, we offer unique and complimentary eco courses to empower our users with knowledge that fosters positive impact. We emphasize responsible usage and strictly prohibit any form of copyright violation, encouraging users to engage with the courses ethically and responsibly. </p>

                        <h4 className="terms-subtitle">9. Your Content </h4>

                        <ol type="a">
                            <li><h4 class="terms-subpara">Posting Content</h4>
                                <p className="terms-para">You might be able to save or share material including text, files, documents, graphics, and photos using our services. "User Content" refers to anything (other than Feedback) that you publish, upload, or otherwise make accessible through the services, including through your Third-Party accounts. Nothing in these Terms will be interpreted as restricting any rights that you may have to your user content. Cero Hero makes no claims of ownership in any user content. </p>

                            </li>
                            <li><h4 class="terms-subpara">Authorization for Your User Content </h4>
                                <p className="terms-para">By making any user content accessible through the services, you hereby grant Cero Hero a non-exclusive, revocable, transferable, worldwide, royalty-free license, with the right to sub-license, use, reproduce, modify, adapt, publish, transladistribute, publicly display, and publicly perform your User Content in connection with running and maintaining the services.  </p>
                            </li>
                            <li><h4 class="terms-subpara">You are accountable for User Content </h4>
                                <p className="terms-para"> All of your user content is entirely your responsibility. You affirm and guarantee that you have all legal authority to give us the license rights in your user content set forth in these Terms, and that you continue to have all such authority. You affirm and warrant that neither your user content, nor the use and provision of your user content to be made available through the Services, nor any use of your user content on or through the services by Cero Hero will infringe, misappropriate, or violate a third party's intellectual property rights, rights of publicity, or rights of privacy, or result in a violation of any applicable law or regulation.  </p>
                            </li>
                            <li><h4 class="terms-subpara">The intellectual property of Cero Hero  </h4>
                                <p className="terms-para"> We could offer content that is protected by intellectual property laws through the services. All ownership of the material belongs to us.  </p>
                            </li>
                            <li><h4 class="terms-subpara">Use this site's information only as a guide  </h4>
                                <p className="terms-para">By accessing and using the content on our website, you acknowledge that it is intended to provide general information only and should not be considered as advice to be relied upon. Before making any decisions or taking any actions based on the information provided, it is essential to seek expert or professional counsel to ensure the appropriateness and accuracy of such actions or decisions. </p>
                                <p className="terms-para">Despite our best attempts to keep the material on this site updated, we make no claims, promises, or guarantees—express or implied—as to the accuracy, completeness, or currentness of the information within. </p>
                            </li>
                            <li><h4 class="terms-subpara">Websites that we link to are not under our control </h4>
                                <p className="terms-para">Links on our site that lead to external websites and other resources run by third parties are just there for your information. These links shouldn't be taken to mean that we endorse the websites they lead to or the data you could find there. The information on those websites and resources is not under our control. </p>
                            </li>
                            <li><h4 class="terms-subpara">We do not endorse user-generated content </h4>
                                <p className="terms-para">This website could include content that users, including those who have posted to chat rooms and bulletin boards, have submitted. We have not verified or authorized this information or these resources. Our views and ideals are not represented by the opinions shared by other users on our website. </p>
                            </li>
                            <li><h4 class="terms-subpara">How to express dissatisfaction with user-uploaded content </h4>
                                <p className="terms-para">Contact us at <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a> if you would want to voice your displeasure with any content that other users have contributed.  </p>
                            </li>
                            <li><h4 class="terms-subpara">Billing and Payment Information</h4>
                                <p className="terms-para">Cero Hero Marketplace collaborates with third-party websites for transactions, and any billing address or payment details, are entirely handled by these respective Third Party Aggregators (TPAs) and their websites. Cero Hero holds no responsibility for the security or handling of such information. Users are encouraged to review and adhere to the terms and conditions of the specific TPAs involved in their transactions. </p>
                            </li>
                            <li><h4 class="terms-subpara">Redemption of Cero Credits</h4>
                                <p className="terms-para">All users have the flexibility to purchase their preferred products by utilizing their accumulated Cero Credits by selecting the "Redeem" option, converting credits into an equivalent monetary amount that is equal to the value of the product. This empowers users to make environmentally conscious choices while enjoying the benefits of their earned Cero Credits. </p>
                            </li>

                        </ol>
                        <h4 className="terms-subtitle">10. Refer and Earn    </h4>
                        <p className="terms-para">Participating in the Refer and Earn program is subject to the following conditions. To qualify for the 5 Cero Credits reward, the referred user must successfully set up their account on a unique device using the dynamic referral link provided. Cero Hero reserves the right to verify the legitimacy of referrals and withhold credits in case of any misuse or fraudulent activities. The Refer and Earn program is designed to reward genuine efforts in spreading awareness, and any violation of these terms may result in disqualification from the program. Cero Hero also reserves the right to modify or terminate the Refer and Earn program at its discretion.  </p>
                        <h4 className="terms-subtitle">11. General Bans and Cero Hero Right to Enforce. You commit to abstaining from all of the following:  </h4>

                        <ol  type="a">
                            <li className="terms-para">
                                Post, upload, publish, submit, or transmit any user content that:
                                <ol type="i">
                                    <li >infringes, misappropriates, or violates a third party's patent, copyright, trademark, trade secret, moral rights, or other intellectual property rights;</li>
                                    <li>violates, or encourages conduct that would violate, any applicable law or regulation or would result in civil liability;</li>
                                    <li>is fraudulent, false, misleading, or deceptive;</li>
                                    <li>is defamatory;</li>
                                    <li>encourages unlawful or hazardous activities or drugs;</li>
                                    <li>is aggressive, threatening, or violent or threatening behavior towards any person or entity.</li>
                                </ol>
                            </li >
                            <li className="terms-para">You are not permitted to use, display, mirror, or frame the services of Cero Hero, or any specific element, name, trademark, logo, proprietary information, or the structure and design of any page or form found on a page, without the explicit written consent of Cero Hero.</li>
                            <li className="terms-para">Access, tamper with, or utilise non-public portions of the services, Cero Hero computer systems, or the technical delivery systems of Cero Hero suppliers.</li>
                            <li className="terms-para">Attempt to probe, scan, or test the vulnerability of any Cero Hero system or network or breach any security or authentication measures.</li>
                            <li className="terms-para">Avoid, evade, remove, disable, damage, scramble, or otherwise go around any technological safeguards put in place to protect the services by Cero Hero, any of Cero Hero's suppliers, or any other third party (including another user).</li>
                            <li className="terms-para">Try to use any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools, or similar) other than the software and/or search agents provided by Cero Hero or other generally accessible third-party web browsers to access or search the services or download content from the services.</li>
                            <li className="terms-para">Send any unauthorized or unwelcome advertisements, promotional materials, emails, junk mail, spam, chain letters, or other types of solicitation.</li>
                            <li className="terms-para">Do not use any meta tags, hidden content, or metadata that contain a Cero Hero trademark, logo URL, or product name without clear written permission from Cero Hero.</li>
                            <li className="terms-para">Use the services, or any part of them, for any profit-making endeavor, or in any other way that is prohibited by these Terms.</li>
                            <li className="terms-para">Alter, falsify, or otherwise use the services to send any TCP/IP packet header, including any portion of the header information, in any email or newsgroup posting.</li>
                            <li className="terms-para">Make an effort to reverse-engineer, decode, decompile, or disassemble any of the software utilized to deliver the services.</li>
                            <li className="terms-para">Obstruct or try to obstruct any user's, host's, or network's access, including, without limitation, by flooding, overloading, spamming, or mail-bombing the services.</li>
                            <li className="terms-para">Gather or save any personally identifiable data from other Service users without getting their express consent.</li>
                            <li className="terms-para">Impersonate someone else or falsely claim association with them.</li>
                            <li className="terms-para">Violate any laws or regulations in force.</li>
                            <li className="terms-para">Incite or assist someone else in engaging in any of the aforementioned behaviors.</li>
                        </ol>
                        <p className="terms-para">Cero Hero is not required to keep an eye on who uses the services or who accesses them, or to look over or alter any material. However, we reserve the right to do so in order to maintain the services, to make sure that these Terms are followed, and to adhere to any relevant laws or other legal obligations. We retain the right, but are under no obligation, to delete or disable access to any content, including User Content, at any time and without prior notice, for any reason, including but not limited to, if we, in our sole judgment, find that it is objectionable or in violation of these Terms.  </p>
                        <p className="terms-para">We have the right to look into any breaches of these Terms or actions that have a negative impact on the services. We could also speak with law enforcement officials and work along with them to pursue users who break the law.</p>
                        <h4 className="terms-subtitle">12. Which nation's legal system governs any disputes?</h4>

                        <p className="terms-para">Please note that if you are a consumer, these conditions of use, their subject matter, and their formation are all governed by Indian law. In the case of businesses, Indian law applies to these conditions of use, their subject matter, their formulation, as well as any non-contractual claims or disputes. By using our website, both parties agree to submit to the exclusive jurisdiction of the Indian courts. </p>
                        <p className="terms-para">If you consider that we do not process your personal data in accordance with the EU’s General Data Protection Regulation, you can submit a complaint to the supervisory authority. That authority in Finland is the Data Protection Ombudsman.</p>
                        <h4 className="terms-subtitle">13. Contact Information </h4>

                        <p className="terms-para">If you have any questions about these Terms and conditions or the services, please contact Cero Hero at <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a>  </p>

                        <h4 className="terms-subtitle">Cero Hero   </h4>
                        <p className="terms-para">E-mail: <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a>  </p>
                        <p className="terms-para">3rd Floor, Gowri Arcade, HIG-A-85,  <br/>
Jayabheri Enclave, Gachibowli, Hyderabad, Telangana 500032  </p>

                    </div>
                    <NavLink className="download-pdf" onClick={handleDownload}><img className="pdf-icons" src="../../images/terms/pdf-icon.png" alt="no-data" />Download</NavLink>

                </div>

            </div>
        </>
    )
}
export default Terms;