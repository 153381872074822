import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const TravelCalculator = () => {
  const [metroValue, setMetroValue] = useState();
  const [railValue, setRailValue] = useState();
  const [airValue, setAirValue] = useState();
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*\.?\d*$/.test(value)) {
      setMetroValue(value);
    }
   
  };
  const handleChangeOne = (event) => {
    const { value } = event.target;
    if (/^\d*\.?\d*$/.test(value)) {
      setRailValue(value);
    }

  };
  const handleChangeTwo = (event) => {
    const { value } = event.target;
    if (/^\d*\.?\d*$/.test(value)) {
      setAirValue(value);
    }
   
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let defaultmetroValue = 0
    let defaultrailValue =0
    let defaultairValue =0
  
    if (metroValue === undefined || metroValue === null || metroValue === '') {
      defaultmetroValue = 0
    }else{
      defaultmetroValue = metroValue
    }
    if (railValue === undefined || railValue === null || railValue === '') {
      defaultrailValue = 0
    }else{
      defaultrailValue = railValue
    }
    if (airValue === undefined || airValue === null || airValue === '') {
      defaultairValue = 0
    }else{
      defaultairValue = airValue
    }
    localStorage.setItem('metroValue',defaultmetroValue)
    localStorage.setItem('railValue',defaultrailValue)
    localStorage.setItem('airValue',defaultairValue)
    window.location.href = `/travel1`;
    // You can perform further actions with the value here
  };
  return (
    <>
    <div className="calculations-content">
      <div className="container">
        <ul className="calcularor-navbar">
          <li>
            <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
            </div></NavLink>
          </li>
        </ul>
        <div className='electiricity-content'>
          <div className='electiricity-top'>
            <h3 className='electiricity-title'>Travel</h3>
            <p className='electiricity-titlewhat'>Please estimate how many kilometres you travel each year using these transport methods. </p>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-2.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Metro
                  </p>
                 <div className='electricity-number'>
                  <input value={metroValue}
                        onChange={handleChange} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>

            </div>
            <div className='col-md-4' >
            <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-3.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Rail                   </p>
                 <div className='electricity-number'>
                  <input value={railValue}
                        onChange={handleChangeOne} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>
            </div>
            <div className='col-md-4' >
            <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-1.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Air                   </p>
                 <div className='electricity-number'>
                  <input value={airValue}
                        onChange={handleChangeTwo} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>
            </div>
          </div>
          <ul className='previous-nextbtns'>
            <li>
              <NavLink to={"/fueluse"} className="prevbtn-data"><div className='previous-btn'>
                <i class="fas fa-arrow-left"></i>
              </div></NavLink>
            </li>
            <li>
              <NavLink onClick={handleSubmit} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </>
  )
}

export default TravelCalculator