import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const TravelPublic = () => {
  const [busValue, setBusValue] = useState();
  const [twowheelerValue, setTwowheelerValue] = useState();
  const [threewheelerValue, setThreewheelerValue] = useState();
  const handleChange = (event) => {
    const { value } = event.target;
   
    if (/^\d*\.?\d*$/.test(value)) {
      setBusValue(value);
    }
  };
  const handleChangeOne = (event) => {
    const { value } = event.target;
   
    if (/^\d*\.?\d*$/.test(value)) {
      setTwowheelerValue(value);
    }
  };
  const handleChangeTwo = (event) => {
    const { value } = event.target;
   
    if (/^\d*\.?\d*$/.test(value)) {
      setThreewheelerValue(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let defaultbusValue = 0
    let defaulttwowheelerValue =0
    let defaultthreewheelerValue =0
    if (busValue === undefined || busValue === null || busValue === '') {
      defaultbusValue = 0
    }else{
      defaultbusValue = busValue
    }
    if (twowheelerValue === undefined || twowheelerValue === null || twowheelerValue === '') {
      defaulttwowheelerValue = 0
    }else{
      defaulttwowheelerValue = twowheelerValue
    }
    if (threewheelerValue === undefined || threewheelerValue === null || threewheelerValue === '') {
      defaultthreewheelerValue = 0
    }else{
      defaultthreewheelerValue = threewheelerValue
    }
    localStorage.setItem('busValue',defaultbusValue)
    localStorage.setItem('twowheelerValue',defaulttwowheelerValue)
    localStorage.setItem('threewheelerValue',defaultthreewheelerValue)
    window.location.href = `/travel2`;
    // You can perform further actions with the value here
  };
  return (
    <>
    <div className="calculations-content">
      <div className="container">
        <ul className="calcularor-navbar">
          <li>
            <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
            </div></NavLink>
          </li>
        </ul>
        <div className='electiricity-content'>
          <div className='electiricity-top'>
            <h3 className='electiricity-title'>Travel</h3>
            <p className='electiricity-titlewhat'>Please estimate how many kilometres you travel each year using these transport methods.</p>
          </div>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-4.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Bus
                  </p>
                 <div className='electricity-number'>
                  <input value={busValue}
                        onChange={handleChange} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>

            </div>
            <div className='col-md-4' >
            <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-5.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Two-wheeler Transport                  </p>
                 <div className='electricity-number'>
                  <input value={twowheelerValue}
                        onChange={handleChangeOne} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>
            </div>
            <div className='col-md-4' >
            <div className="card electricity-card" >
                <div className='electricity-card-image electricity-card-image1'>
                <img src="./images/calculator/travel-image-6.webp" className="card-img-top card-image-data" alt="..." />
                </div>
                <div className="card-body electiricity-body">
               
                  <p className="card-text electiricity-para electiricity-para1">
                  Three-wheeler Transport                  </p>
                 <div className='electricity-number'>
                  <input value={threewheelerValue}
                        onChange={handleChangeTwo} type='tel' className='electricity-number-inp' placeholder='000000' maxLength={6} /> <span className='mesure-category'>km</span>
                 </div>
                 <p className='entervalue-text'>Enter value</p>
                </div>
              </div>
            </div>
          </div>
          <p className='line-image-data'><img className='line-image' src="./images/calculator/line-image-1.webp" alt='no-line' /></p>
          <ul className='previous-nextbtns'>
            <li>
              <NavLink to={"/travel"} className="prevbtn-data"><div className='previous-btn'>
                <i class="fas fa-arrow-left"></i>
              </div></NavLink>
            </li>
            <li>
              <NavLink onClick={handleSubmit} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
            </li>
          </ul>

        </div>


      </div>
    </div>
  </>
  )
}

export default TravelPublic