import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Advisory = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
    <Helmet>
        <title>Our Advisory Board</title>
      </Helmet>
      <div className="advisory-page">
        <div className="advisory-heroSection">
          <div className="container">
            <div className="hero-left">
              <h2 className="advisory-title">
                Advisory <br className="d-none d-md-block" />
                Board
              </h2>
              <span className="pioneering-text">
                Pioneering Sustainability with{" "}
                <h4 className="cero-adbvisory">Cero Hero Advisory</h4>
              </span>
            </div>
          </div>
        </div>
        <div className="advisers-data-below ">
          <div className="container">
            <div className="adviser-linkdin-details">
              <h3 className="adviser-name">
                Harbachan Singh - Advisor, Cero Hero
              </h3>
              <hr className="line-draw" />
              <div className="adiversers-lists">
                <div className="row h-100">
                  <div className="col-md-6">
                    <div className="profile-data-left ">
                      <h3 className="profile-summary">Profile Summary:</h3>
                      <p className="sumary-text">
                        We are privileged to welcome Mr. Harbachan Singh,
                        Chairman of the Headquarters Committee on Contracts at
                        the United Nations, as a distinguished member of our
                        Advisory Board. With an extensive background in
                        community empowerment and cultural exchange, Mr. Singh
                        embodies the values central to our mission. His
                        strategic vision and deep commitment to global community
                        initiatives make him a pivotal addition to our team.
                      </p>
                      <div className="profile-image-top">
                        <img
                          className="profile-pic"
                          src="images/advisory/prof1.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-data-left background-data-right profile1-right">
                      <h5 className="title-text1">Background:</h5>
                      <p className="data-text2">
                        Holding a master’s degree in international relations,
                        Mr. Singh has devoted his career to strengthening the
                        global Indian diaspora through his leadership at the
                        Global Organization of People of Indian Origin (GOPIO).
                        As the International Coordinator for North America, he
                        has been pivotal in launching initiatives that elevate
                        cultural awareness and foster economic and educational
                        growth.
                      </p>
                      <h5 className="title-text1">Roles and Contributions:</h5>
                      <p className="data-text2">
                        At Cero Hero, Mr. Singh leverages his substantial
                        network and expertise in global affairs to enhance our
                        community engagement and intercultural cooperation
                        strategies. His skills in policy influence and community
                        leadership are instrumental as we develop sustainable,
                        community-centred solutions.
                      </p>
                      <h5 className="title-text1">Vision and Impact:</h5>
                      <p className="data-text2">
                        Mr. Singh’s vision aligns seamlessly with Cero Hero’s
                        goal of leveraging collective strengths to achieve
                        significant impact. His dedication to the Indian
                        diaspora and his efforts to promote global dialogue and
                        collaboration are crucial as we address the evolving
                        challenges and opportunities in our mission.
                      </p>

                      <a className="linkdin-profile text-decoration-none" href="https://www.linkedin.com/in/harbachan-singh-19825a19/" target="_blank" rel="noopener noreferrer">
                        <img
                          className="linkdin-image"
                          src="/images/advisory/linkdn.png"
                        />
                        <h4 className="profile-link">Mr. Harbachan Singh </h4>
                        </a>
                      
                      <hr className="line-draw" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="adviser-linkdin-details">
              <h3 className="adviser-name">
              Ovais Sarmad
              - Advisor, Cero Hero 
              </h3>
              <hr className="line-draw" />
              <div className="adiversers-lists">
                <div className="row h-100">
                <div className="col-md-6">
                    <div className="profile-data-left background-data-right profile2-left">
                      <h5 className="title-text1">Background:</h5>
                      <p className="data-text2 text-pading">
                      Mr. Ovais Sarmad is a leader in climate change, migration, and multilateralism. At the UN Climate Change secretariat (UNFCCC) from COP23 to COP27, he managed operations, advised on strategic planning, and achieved key milestones like the Paris Agreement guidelines and Loss & Damage fund. With over three decades of experience, including 27 years at the International Organization for Migration (IOM), Mr. Sarmad specializes in organizational change, policy development, and resource mobilization. He now focuses on sustainable development, public policy, youth empowerment, and advising on multilateralism.
                      </p>
                      <h5 className="title-text1">Roles and Contributions:</h5>
                      <p className="data-text2 text-pading">
                      Mr. Sarmad started his career in London, qualifying as a Chartered Management Accountant (ACMA) and Chartered Global Management Accountant (GCMA) in 1988 after earning a Bachelor of Commerce from Osmania University, India, in 1982. Before the UNFCCC, he was the Director of Cabinet and Chief of Staff at IOM, spending 27 years in management and policy roles, leading significant organizational changes.

                      </p>
                      <h5 className="title-text1">Vision and Impact:</h5>
                      <p className="data-text2 text-pading mb-lg-0">
                      At the UNFCCC, Mr. Sarmad managed operations from COP23 to COP27, advised on strategic planning, and completed the Paris Agreement guidelines and Loss & Damage fund. He initiated a secretariat-wide structure review and the first annual report. At IOM, he led organizational change, established emergency operation offices, and introduced projectization and delocalization processes, completing IOM’s affiliation to the UN Joint Staff Pension Fund and negotiating the IOM-UN relationship agreement.

                      </p>

                      
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-data-left background-data-right  profile2-right">
                      <h3 className="profile-summary">Profile Summary:</h3>
                      <p className="sumary-text">
                      Mr. Sarmad aims to foster sustainable development without harming the environment. He contributes to public policy, empowers youth through education, and advises on multilateralism. His leadership has advanced climate action and organizational efficiency, and he continues to drive innovative solutions to global challenges.
                      </p>
                     
                          <a className=" linkdin-profile linkdin2 text-decoration-none" href="https://www.linkedin.com/in/ovais-sarmad/" target="_blank" rel="noopener noreferrer">
                        <img
                          className="linkdin-image"
                          src="/images/advisory/linkdn.png"
                        />
                        <h4 className="profile-link">Mr. Ovais Sarmad</h4>
                        </a>
                      <hr className="line-draw" />
                      <div className="profile-image-top image2">
                        <img
                          className="profile-pic"
                          src="images/advisory/prof2.png"
                        />
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="adviser-linkdin-details adviser-space">
              <h3 className="adviser-name">
              Sreedhar Bevara - Advisor, Cero Hero
              </h3>
              <hr className="line-draw" />
              <div className="adiversers-lists ">
                <div className="row h-100">
                  <div className="col-md-6">
                    <div className="profile-data-left profile3-left">
                      <h3 className="profile-summary">Profile Summary:</h3>
                      <p className="sumary-text">
                      We are proud to have Mr. Sreedhar Bevara as a distinguished member of the Cero Hero Advisory Board. Mr. Bevara brings a wealth of experience from his extensive career in leadership roles at major corporations like Panasonic and LG, as well as his insightful work as an author and motivational speaker.
                      </p>
                      <div className="profile-image-top image3">
                        <img
                          className="profile-pic"
                          src="images/advisory/prof3.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-data-left background-data-right profile3-right">
                      <h5 className="title-text1">Background:</h5>
                      <p className="data-text2">
                      With over two decades of experience in strategic planning, operational excellence, and leadership development, Mr. Bevara has demonstrated his ability to navigate complex business challenges and drive significant organizational growth. His best-selling books on leadership and decision-making reflect his deep understanding of effective management and inspirational leadership.
                      </p>
                      <h5 className="title-text1">Roles and Contributions:</h5>
                      <p className="data-text2">
                      At Cero Hero, Mr. Bevara advises on strategic initiatives and leadership development, leveraging his expertise to enhance our organizational capabilities and impact. His guidance is instrumental in shaping our approach to team building and strategic decision-making, ensuring we remain at the forefront of innovation in our field.
                      </p>
                      <h5 className="title-text1">Vision and Impact:</h5>
                      <p className="data-text2">
                      Mr. Bevara's vision for transformative leadership aligns perfectly with Cero Hero’s mission to empower communities and lead innovative projects with a sustainable impact. His commitment to fostering leadership qualities across teams will be invaluable as we continue to grow and make a positive difference.
                      </p>

                      
                      <a className=" linkdin-profile text-decoration-none " href="https://www.linkedin.com/in/sreedhar-bevara-7b51637/" target="_blank" rel="noopener noreferrer">
                        <img
                          className="linkdin-image"
                          src="/images/advisory/linkdn.png"
                        />
                        <h4 className="profile-link">Mr. Sreedhar Bevara </h4>
                        </a>
                     
                      <hr className="line-draw" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advisory;
