const ApiNames = {

    Subscribe_Newsletter:"subscribe",
    Cerohero_Contactus:"contact-us",
    Cerohero_AllBlogs:"blog",
    Cerohero_Blogdata:"blog/post/",
    Cerohero_Countries:"calc/countries",
    Cerohero_Calculate:"calc/submit",

  
   
}

export default ApiNames;