import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';
const Rewards = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    const location = useLocation();
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = ApiNames.Subscribe_Newsletter;
        const body = {
            email: subscribemail,
        };
    
        Axios.post(url, body)
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };
    useEffect(() => {
        const hash = location.hash;
        if(hash){
         const id =hash.substring(1);
         const element = document.getElementById(id);
         if(element){
           element.scrollIntoView({
             behavior:"smooth"
           });
         }
        }
       
       }, [location.hash])
    return (
        <>
           <Helmet>
                <title>
                    Rewards
                </title>
            </Helmet>
            <div className="rewards-content">
                <div className="rewards-herosection">
                    <div className="container">
                        <div className='rewards-hero'>
                            <h3 className='rewards-title'>{t('rewards.Rewards')}</h3>
                            <p className='rewards-subtitle'>{t('rewards.Unlock a World')}</p>
                        </div>

                    </div>

                </div>
                <div className="marketplace-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="marketplace-left">
                                    <span className="marketplace-top">
                                    {t('rewards.Marketplace')}
                                    </span>
                                    <h3 className="marketplace-title">{t('rewards.Welcome to ZeroHero')}</h3>
                                    <p className="marketplace-para">{t('rewards.Step into a')}</p>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="marketplace-right">
                                    <div className='marketplace-image-data'>
                                        <img className='marketplace-image' src='../images/rewards/rewards-hero.webp' alt="CeroHero's Marketplace, sustainable actions translate into tangible rewards." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spotlight-section">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="spotlight-highlits">
                                    <img src="../images/rewards/market-1.png" className="spotlight-highlits-image" alt="no-data" />
                                    <h5 className="spotlight-highlits-top">{t('rewards.Spotlight')}</h5>
                                    <h3 className="spotlight-highlits-title">{t('rewards.Eco-Friendly Products')}</h3>
                                    <p className="spotlight-highlits-para">{t('rewards.Explore a range')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="spotlight-highlits">
                                    <img src="../images/rewards/market-2.png" className="spotlight-highlits-image" alt="no-data" />
                                    <h5 className="spotlight-highlits-top">{t('rewards.Spotlight')}</h5>
                                    <h3 className="spotlight-highlits-title">{t('rewards.Exclusive Discounts')}</h3>
                                    <p className="spotlight-highlits-para">{t('rewards.Unlock special discounts')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="spotlight-highlits">
                                    <img src="../images/rewards/market-3.png" className="spotlight-highlits-image" alt="no-data" />
                                    <h5 className="spotlight-highlits-top">{t('rewards.Spotlight')}</h5>
                                    <h3 className="spotlight-highlits-title">{t('rewards.Gift a Tree')}</h3>
                                    <p className="spotlight-highlits-para">{t('rewards.Use your credits')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <NavLink className="spotlight-highlits-button">{t('rewards.Explore the Marketplace')}</NavLink> */}
                    </div>

                </div>
                <div className="ourrewards-section">
                    <div className="container">
                        <div className="ourrewards-top">
                            <h3 className="ourrewards-title">{t('rewards.Our Rewards')}</h3>
                            <p className="ourrewards-para">{t('rewards.Earn, Redeem, Celebrate')}</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="ourrewards-highlits">
                                    <img src="../images/rewards/process-1.png" className="ourrewards-highlits-image" alt="no-data" />
                                    <h3 className="ourrewards-highlits-title">{t('rewards.Earn Credits')}</h3>
                                    <p className="ourrewards-highlits-para">{t('rewards.Accumulate carbon')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="ourrewards-highlits">
                                    <img src="../images/rewards/process-2.png" className="ourrewards-highlits-image" alt="no-data" />
                                    <h3 className="ourrewards-highlits-title">{t('rewards.Browse & Choose')}</h3>
                                    <p className="ourrewards-highlits-para">{t('rewards.Explore ZeroHero')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="ourrewards-highlits">
                                    <img src="../images/rewards/process-3.png" className="ourrewards-highlits-image" alt="no-data" />
                                    <h3 className="ourrewards-highlits-title">{t('rewards.Redeem & Enjoy')}</h3>
                                    <p className="ourrewards-highlits-para">{t('rewards.Use your carbon')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <NavLink className="ourrewards-highlits-button">{t('rewards.Learn More About')}</NavLink> */}
                    </div>

                </div>
                {/* <div className="rewardpartner-section">
                    <div className="container">
                        <div className="rewardpartner-top">
                            <h3 className="rewardpartner-title">{t('rewards.Reward Partners')}</h3>
                            <p className="rewardpartner-para">{t('rewards.Meet Our Esteemed')}</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="rewardpartner-highlits">
                                    <div className="rewardpartner-highlits-image">
                                    <img src="../images/landing/wavpe-image.webp" className="rewardpartner-highlits-image" alt="no-data" />
                                    </div>
                                    <h3 className="rewardpartner-highlits-title">Wavpe</h3>
                                    <p className="rewardpartner-highlits-para">{t('rewards.Discover a range')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="rewardpartner-highlits">
                                    <div className="rewardpartner-highlits-image">
                                    <img src="../images/landing/gm-image.webp" className="rewardpartner-highlits-image" alt="no-data" />

                                    </div>
                                    <h3 className="rewardpartner-highlits-title">Devotion Tourism</h3>
                                    <p className="rewardpartner-highlits-para">{t('rewards.Unlock exclusive')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="rewardpartner-highlits">
                                    <div className="rewardpartner-highlits-image">
                                    <img src="../images/landing/devotion-image.webp" className="rewardpartner-highlits-image" alt="no-data" />

                                    </div>
                                    <h3 className="rewardpartner-highlits-title">GM Global Enterprise</h3>
                                    <p className="rewardpartner-highlits-para">{t('rewards.Dive into')}</p>
                                </div>
                            </div>
                        </div>
                        <NavLink to={"/partners"} className="rewardpartner-highlits-button">{t('rewards.View All Partners')}</NavLink>
                    </div>

                </div> */}
                <div className="redeemcredits-section" id="redeemcredits">
                    <div className="container">
                        <div className="redeemcredits-top">
                            <h3 className="redeemcredits-title">{t('rewards.Redeem Credits')}</h3>
                            <p className="redeemcredits-para">{t('rewards.Your Credits')}</p>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="redeemcredits-vision">
                                 
                                    <div className="redeemcredits-body">
                                        <h3 className="redeemcredits-body-title">{t('rewards.How to Redeem')}</h3>
                                        <p className="redeemcredits-body-para">{t('rewards.Browse through')}</p>
                                      
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="redeemcredits-vision">
                                   
                                    <div className="redeemcredits-body">
                                        <h3 className="redeemcredits-body-title">{t('rewards.Track Your Credits')}</h3>
                                        <p className="redeemcredits-body-para">{t('rewards.Monitor your')}</p>
                                       
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="redeemcredits-vision">
                                   
                                    <div className="redeemcredits-body">
                                        <h3 className="redeemcredits-body-title">{t('rewards.Gift and Donate')}</h3>
                                        <p className="redeemcredits-body-para">{t('rewards.Choose to gift')}</p>
                                    
                                    </div>

                                </div>

                            </div>
                        </div>
                        {/* <NavLink className="redeemcredits-button">{t('rewards.Redeem Your')}</NavLink> */}

                    </div>
                </div>
                <div className="subscribe-content subscribe-content1">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Rewards