import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";

const TeamPage = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    team
                </title>
            </Helmet>
            <div className='team-content'>
                <div className='container'>
                    <h3 className='team-title'>Cero Hero Team: <br />
                        United in Vision, Global in Action</h3>

                    <div className="card team-topcard mb-3" >
                        <div className="row g-0">
                            <div className="col-md-4">
                                <div className='teamtop-left'>
                                    <img src="../images/team/boss-image.png" className="img-fluid teamleft-image rounded-start" alt="..." />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="card-body teamtop-body">
                                    <p className="card-text teamtop-para">Saitej Annareddy, a seasoned entrepreneur with a deep
                                        passion for blockchain technology, serves as the Founder and
                                        CEO of Cero Hero. His journey in the blockchain landscape has established him as a premier blockchain strategist. With a background in managing and founding several ventures like
                                        Chakravuyha, Coinovy OU, Smashit.live, and DLT Labz, Saitej
                                        brings a wealth of experience in both management and
                                        blockchain technology.

                                    </p>
                                    <p className="card-text teamtop-para">
                                        He excels in identifying customer pain points and innovating
                                        solutions, focusing on creating and scaling blockchain
                                        technology solutions in every project. His latest venture,
                                        Coinovy, addresses critical issues in the industry, aiming to
                                        provide simplified digital financial services. Saitej's strength
                                        lies in rapidly conceptualizing new ventures and turning each into a successful endeavor.


                                    </p>
                                    <p className="card-text teamtop-para">As the Founder and CEO of Cero Hero, Saitej Annareddy
                                        brings his extensive experience in blockchain strategy and
                                        business management to spearhead our mission towards a
                                        sustainable future. With his previous ventures in blockchain
                                        technology and his vision for simplified digital finances, Saitej is adept at integrating innovative solutions with environmental consciousness at Cero Hero. His forward
                                        thinking approach and commitment to eco-friendly practices are driving forces in our journey to empower communities through sustainable actions
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className='team-title team-title1'>Team and Company <br />
                        Background</h3>

                    <ul className='teammembers-info-ul'>
                        <li className='teammembers-info-li'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Reddy Shyam </h3>
                                    <h4 className='teammembers-info-subtitle'>Corporate Sustainability
                                        Liaison and Educational Content Developer</h4>
                                    <p className='teammembers-info-para'>Collaborates with businesses and corporations to encourage sustainable practices and partnerships within the app. Creates informative and engaging content within the app to educate users about sustainability.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li1'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Siddharth  </h3>
                                    <h4 className='teammembers-info-subtitle'>Eco-Application Security Head Advisor</h4>
                                    <p className='teammembers-info-para'>Siddharth, as the Application Security Head, safeguards Cero Hero against potential threats, ensuring the confidentiality and integrity of user data.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li2'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Sri Hari
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Sustainable AI Development Head Advisor</h4>
                                    <p className='teammembers-info-para'>Sri Hari, as the AI Development Head, spearheads the advancement of artificial intelligence within Cero Hero, pushing the boundaries of what our application can achieve.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li3'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Reddy Shiva </h3>
                                    <h4 className='teammembers-info-subtitle'>Sustainability Strategist</h4>
                                    <p className='teammembers-info-para'>As a Sustainability Strategist, Shiva Reddy is dedicated to making a meaningful difference by integrating sustainability principles into the core of organizational values and messaging, contributing to a more environmentally conscious and socially responsible future.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <h3 className='team-title team-title1'>Team and Company <br />
                        Background</h3>

                    <ul className='teammembers-info-ul'>
                        <li className='teammembers-info-li'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-1.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Spandhana Babu </h3>
                                    <h4 className='teammembers-info-subtitle'>Sustainability and Social Responsibility (SSR) Lead</h4>
                                    <p className='teammembers-info-para'>Spandhana Babu, as the SSR Lead, oversees the app's alignment with sustainability and social responsibility goals, fostering a positive impact on communities.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li1'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-2.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Siva Rama datta</h3>
                                    <h4 className='teammembers-info-subtitle'>Lead Green Software Engineer</h4>
                                    <p className='teammembers-info-para'>Siva Ramadatta, the Lead Software Engineer at Cero Hero, leads the technical development, ensuring the robustness and scalability of the application's software infrastructure.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li2'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-3.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Sunilkumar K </h3>
                                    <h4 className='teammembers-info-subtitle'>Sustainable Product Manager</h4>
                                    <p className='teammembers-info-para'>As the Product Manager for Cero Hero, Sunil Kumar K leads the development of eco-conscious features and ensures the alignment of the app with sustainability, ensuring it aligns with user needs and market trends. Responsible for overseeing and managing the overall sustainability initiatives within the application, ensuring alignment with environmental goals.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li3'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-4.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Sri DeviSankar</h3>
                                    <h4 className='teammembers-info-subtitle'>Environmental Data Scientist & Business Analyst</h4>
                                    <p className='teammembers-info-para'>Sridevi specializes in crafting ecofriendly and sustainable technologies, seamlessly integrating them into the Cero Hero app for a positive environmental impact. As a skilled Data Scientist and AI Developer, she leverages advanced analytics and artificial intelligence to enhance the app's intelligence and functionality.</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ul className='teammembers-info-ul'>
                        <li className='teammembers-info-li'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-5.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Nishant Kumar
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Green Tech Software & Blockchain Developer</h4>
                                    <p className='teammembers-info-para'>Nishant Kumar, our Senior Software Engineer and Blockchain Developer, integrates blockchain technology into Cero Hero, fortifying security and transparency within the application.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li1'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-6.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Manne Uday Krishna </h3>
                                    <h4 className='teammembers-info-subtitle'>Waste Reduction Strategist & Eco-Marketing Strategist</h4>
                                    <p className='teammembers-info-para'>Manne Uday Krishna, the EcoMarketing Specialist, promotes sustainable practices and products, driving green initiatives in digital marketing for Cero Hero, ensuring a strong online presence and effective outreach to our target audience, promotes sustainable practices and products, driving green initiatives in digital marketing.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li2'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-7.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Thirupathi Rao Manchineni
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Senior iOS Sustainability Developer</h4>
                                    <p className='teammembers-info-para'>Senior iOS Sustainability Developer, leads the charge in developing environmentally conscious features for Apple users. Thirupathi Rao Manchineni, our Senior iOS Developer, leads the development efforts for the iOS version of Cero Hero, delivering a seamless and optimized experience for Apple users.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li3'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-8.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Basani Charan
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Senior Sustainable Android Developer</h4>
                                    <p className='teammembers-info-para'>Senior Sustainable Android Developer, pioneers the development of eco-friendly features, ensuring a green footprint for Android users, plays a key role in shaping the Android version of Cero Hero, ensuring a smooth and feature-rich experience for users on the platform.</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ul className='teammembers-info-ul'>
                        <li className='teammembers-info-li'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-9.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Kurra Kali Babu
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Eco-Graphic Designer</h4>
                                    <p className='teammembers-info-para'>Kurra Kali Babu, our Senior Graphic Designer, brings creativity and visual appeal to Cero Hero, designing captivating visuals that enhance the overall user interface and experience through visually appealing designs that convey the app's green ethos.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li1'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-10.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Narasimha Botlapati </h3>
                                    <h4 className='teammembers-info-subtitle'>Eco Program Analyst</h4>
                                    <p className='teammembers-info-para'>Narasimha the Eco Program Analyst, optimizes sustainability initiatives within the app, driving efficiency and positive environmental impact.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li2'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-11.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Piradi Nikhita Satya Priya
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Green Content Strategist</h4>
                                    <p className='teammembers-info-para'>Piradi Nikhita Satya Priya, the Green Content Strategist, shapes environmentally conscious narratives, promoting sustainable practices and content within the app crafts compelling narratives and engaging content strategies, shaping the brand image and user experience for Cero Hero.</p>
                                </div>
                            </div>
                        </li>
                        <li className='teammembers-info-li3'>
                            <div className="teammembers-info-card d-flex">
                                <div className="flex-shrink-0">
                                    <img className='teammembers-info-image' src="../images/team/team-icon-12.png" alt="no-data" />
                                </div>
                                <div className="teammembers-info-body flex-grow-1 ms-3">
                                    <h3 className='teammembers-info-title'>Thallapalli Manasa
                                    </h3>
                                    <h4 className='teammembers-info-subtitle'>Carbon-Aware Tester</h4>
                                    <p className='teammembers-info-para'>A tester who actively promotes and champions sustainable practices within the realm of software testing.</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </>
    )
}

export default TeamPage