import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'toastr/build/toastr.css';
import ApiNames from '../../../Constants/ApiNames';
import Axios from './../../../Utils/AxiosConfig';

const MealsCalculator = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [meatPreference, setMeatPreference] = useState(null);
  const [getVegan, setVegan] = useState(false);
  const [getVeg, setVeg] = useState(false);
  const [getRareEat, setRareEat] = useState(false);
  const [getSomeEat, setSomeEat] = useState(false);
  const [getMostEat, setMostEat] = useState(false);
  const [getMealType, setMeatType] = useState(false);
  const [getMeatEater, setMeatEter] = useState(false);
  const [calculatedData, setCalculatedData] = useState(null);


  const [getMeat, setMeat] = useState(false);


  const handleOptionChange = (option) => {
    setSelectedOption(option);
   
    // Reset meat preference when a different option is selected
    setMeatPreference(null);

    if(option === 'vegan'){
      setVegan(true)
      setMeatEter(false)
      setVeg(false)
      setMeat(false)
    }
    if(option === 'vegetarian'){
      setVegan(false)
      setVeg(true)
      setMeatEter(false)
      setMeat(false)
    }
    if(option === 'meat'){
      setVegan(false)
      setVeg(false)
      setMeatEter(true)
      setMeat(true)
    }
  };

  const handleMeatPreferenceChange = (preference) => {
    setMeatPreference(preference);
    setMeatType(preference)
    if(preference === 'rareEat'){
      setRareEat(true)
      setSomeEat(false)
      setMostEat(false)
    }
    if(preference === 'someEat'){
      setRareEat(false)
      setSomeEat(true)
      setMostEat(false)
    }
    if(preference === 'mostEat'){
      setRareEat(false)
      setSomeEat(false)
      setMostEat(true)
    }
  };

  // Function to render description based on selected option
  const renderDescription = () => {
    switch (selectedOption) {
      case 'vegan':
        return <p>Note: Vegans do not consume any animal products, including meat, dairy, eggs, and sometimes even honey.</p>;
      case 'vegetarian':
        return <p>Note: Vegetarians do not eat meat but may consume other animal products such as dairy and eggs.</p>;
      case 'meat':
        switch (meatPreference) {
          case 'rareEat':
            return <p>Note: Very rare meals 2412 kg of CO2 per person per annum</p>;
          case 'someEat':
            return <p>Note: Some meals 3017 kg of CO2 per person per annum</p>;
          case 'mostEat':
            return <p>Note: Mostly meals 3781 kg of CO2 per person per annum</p>;
          default:
            return null;
        }
      default:
        return null;
    }
  };
  const NextSubmit = async () =>{
      console.log('info')
      localStorage.removeItem('carbonEmission')
      if(getMeat === true && !getMealType){
        alert("Value required")
        return
      }
     
  
      const url = ApiNames.Cerohero_Calculate;
      const body = {
        countryId: parseInt(localStorage.getItem('countryId')) ,
        totalPersons: localStorage.getItem('selectedPersons'),
        electricity: parseInt(localStorage.getItem('electricityValue')) ,
        renewalPercentage: parseInt(localStorage.getItem('solarValue')) ,
        gas:parseInt(localStorage.getItem('gasValue')) ,
        coal: parseInt(localStorage.getItem('CoalValue')) ,
        wood: parseInt(localStorage.getItem('woodValue')),
        flight: parseInt(localStorage.getItem('airValue')),
        rail: parseInt(localStorage.getItem('railValue')),
        metro: parseInt(localStorage.getItem('metroValue')),
        normalBus: parseInt(localStorage.getItem('busValue')),
        petrol2w: parseInt(localStorage.getItem('twowheelerValue')),
        petrol3w: parseInt(localStorage.getItem('threewheelerValue')),
        petrolCar: parseInt(localStorage.getItem('petrolCarValue')),
        dieselCar: parseInt(localStorage.getItem('dieselCarValue')),
        cngCar: parseInt(localStorage.getItem('cngCarValue')),
        eleBus: parseInt(localStorage.getItem('electricBusValue')),
        electric2w: parseInt(localStorage.getItem('electricBikeValue')),
        electricCar: parseInt(localStorage.getItem('electricCarValue')),
        vegan: getVegan,
        veg: getVeg,
        nonVeg: getMeat,
        mostMeals: getMostEat,
        someMeals: getSomeEat,
        rarely: getRareEat,
      
      };
      Axios.post(url,body)
          .then((res) => {
            setCalculatedData(res.data);
            localStorage.setItem("carbonEmission",res.data.carbonEmission);
            window.location.href = `/carbonfootprint`;
          })
          .catch((err) => {
              console.log(err);
          });

  }
 
  return (
    <>
      <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='electiricity-content'>
            <div className='electiricity-top'>
              <h3 className='electiricity-title'>Meals</h3>
              <p className='electiricity-titlewhat'>What type of food do you mostly eat? </p>
            </div>
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <div className="card electricity-card">
                  <div className='electricity-card-image electricity-card-image1'>
                    <img src="./images/calculator/meal-image-1.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">
                    <p className="card-text electiricity-para electiricity-para1">Vegan</p>
                    <div className='electricity-number'>
                      <div className="form-check form-check-inline chekBox-top">
                        <input
                          className="form-check-input chek-Inputt"
                          type="radio"
                          name="mealOption"
                          id="veganRadio"
                          value="vegan"
                          checked={selectedOption === 'vegan'}
                          onChange={() => handleOptionChange('vegan')}
                        />
                        <label className="form-check-label most-meals" htmlFor="veganRadio">Select</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card electricity-card">
                  <div className='electricity-card-image electricity-card-image1'>
                    <img src="./images/calculator/meal-image-2.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">
                    <p className="card-text electiricity-para electiricity-para1">Vegetarian</p>
                    <div className='electricity-number'>
                      <div className="form-check form-check-inline chekBox-top">
                        <input
                          className="form-check-input chek-Inputt"
                          type="radio"
                          name="mealOption"
                          id="vegetarianRadio"
                          value="vegetarian"
                          checked={selectedOption === 'vegetarian'}
                          onChange={() => handleOptionChange('vegetarian')}
                        />
                        <label className="form-check-label most-meals" htmlFor="vegetarianRadio">Select</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card electricity-card">
                  <div className='electricity-card-image electricity-card-image1'>
                    <img src="./images/calculator/meal-image-3.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body electiricity-body1">
                    <p className="card-text electiricity-para electiricity-para1">Meat Eater</p>
                    <div className='electricity-number'>
                      <div className="form-check form-check-inline chekBox-top">
                        <input
                          className="form-check-input chek-Inputt"
                          type="radio"
                          name="mealOption"
                          id="meatRadio"
                          value="meat"
                          checked={selectedOption === 'meat'}
                          onChange={() => handleOptionChange('meat')}
                        />
                        <label className="form-check-label" htmlFor="meatRadio">Select</label>
                      </div>
                    </div>
                    {selectedOption === 'meat' && (
                      <div className='meat-chechboxss'>
                        <span className='top-directions'></span>
                        <div className="form-check form-check-inline chekBox-top">
                          <input
                            className="form-check-input chek-Inputt"
                            type="radio"
                            name="meatPreference"
                            id="rareEatRadio"
                            value="rareEat"
                            checked={meatPreference === 'rareEat'}
                            onChange={() => handleMeatPreferenceChange('rareEat')}
                          />
                          <label className="form-check-label" htmlFor="rareEatRadio">Rare Eat</label>
                        </div>
                        <div className="form-check form-check-inline chekBox-top">
                          <input
                            className="form-check-input chek-Inputt"
                            type="radio"
                            name="meatPreference"
                            id="someEatRadio"
                            value="someEat"
                            checked={meatPreference === 'someEat'}
                            onChange={() => handleMeatPreferenceChange('someEat')}
                          />
                          <label className="form-check-label" htmlFor="someEatRadio">Some Eat</label>
                        </div>
                        <div className="form-check form-check-inline chekBox-top">
                          <input
                            className="form-check-input chek-Inputt"
                            type="radio"
                            name="meatPreference"
                            id="mostEatRadio"
                            value="mostEat"
                            checked={meatPreference === 'mostEat'}
                            onChange={() => handleMeatPreferenceChange('mostEat')}
                          />
                          <label className="form-check-label" htmlFor="mostEatRadio">Most Eat</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='electiricity-top'>
              <p className='electiricity-titlewhat electiricity-titlewhat1'>{renderDescription()}</p>
            </div>

            <ul className='previous-nextbtns'>
              <li>
                <NavLink to={"/travel3"} className="prevbtn-data"><div className='previous-btn'>
                  <i className="fas fa-arrow-left"></i>
                </div></NavLink>
              </li>
              <li>
                <NavLink onClick={NextSubmit}  className="next-btn">Calculate<img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MealsCalculator;
