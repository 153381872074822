import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const FuelCalculator = () => {
  const [gasValue, setGasValue] = useState();
  const [woodValue, setWoodValue] = useState();
  const [CoalValue, setCoalValue] = useState();
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*\.?\d*$/.test(value)) {
      setGasValue(value);
    }
   
  };
  const handleChangeOne = (event) => {
    const { value } = event.target;
  
    if (/^\d*\.?\d*$/.test(value)) {
      setWoodValue(value);
    }
  };
  const handleChangeTwo = (event) => {
    const { value } = event.target;
  
    if (/^\d*\.?\d*$/.test(value)) {
      setCoalValue(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let defaultgasValue = 0
    let defaultwoodValue =0
    let defaultCoalValue =0
    
    if (gasValue === undefined || gasValue === null || gasValue === '') {
      defaultgasValue = 0
    }else{
      defaultgasValue = gasValue
    }
    if (woodValue === undefined || woodValue === null || woodValue === '') {
      defaultwoodValue = 0
    }else{
      defaultwoodValue = woodValue
    }
    if (CoalValue === undefined || CoalValue === null || CoalValue === '') {
      defaultCoalValue = 0
    }else{
      defaultCoalValue = CoalValue
    }
    localStorage.setItem('gasValue',defaultgasValue)
    localStorage.setItem('woodValue',defaultwoodValue)
    localStorage.setItem('CoalValue',defaultCoalValue)
    window.location.href = `/travel`;
    // You can perform further actions with the value here
  };
  return (
    <>
      <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='electiricity-content'>
            <div className='electiricity-top'>
              <h3 className='electiricity-title'>Household fuel usage</h3>
              <p className='electiricity-titlewhat'>Please enter your household’s total annual fuel usage for cooking and heating. </p>
            </div>
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <div className="card electricity-card" >
                  <div className='electricity-card-image electricity-card-image1'>
                  <img src="./images/calculator/fuel-image-1.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">
                 
                    <p className="card-text electiricity-para electiricity-para1">
                    LPG/CNG usage
                    </p>
                   <div className='electricity-number'>
                    <input value={gasValue}
                        onChange={handleChange} type='tel' className='electricity-number-inp' placeholder='000' maxLength={3} /> <span className='mesure-category'>Kg</span>
                   </div>
                   <p className='entervalue-text'>Enter value</p>
                  </div>
                </div>

              </div>
              <div className='col-md-4' >
              <div className="card electricity-card" >
                  <div className='electricity-card-image electricity-card-image1'>
                  <img src="./images/calculator/fuel-image-2.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">
                 
                    <p className="card-text electiricity-para electiricity-para1">
                    Wood usage                    </p>
                   <div className='electricity-number'>
                    <input value={woodValue}
                        onChange={handleChangeOne} type='tel' className='electricity-number-inp' placeholder='000' maxLength={3} /> <span className='mesure-category'>Kg</span>
                   </div>
                   <p className='entervalue-text'>Enter value</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4' >
              <div className="card electricity-card" >
                  <div className='electricity-card-image electricity-card-image1'>
                  <img src="./images/calculator/fuel-image-3.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">
                 
                    <p className="card-text electiricity-para electiricity-para1">
                    Coal usage                    </p>
                   <div className='electricity-number'>
                    <input value={CoalValue}
                        onChange={handleChangeTwo} type='tel' className='electricity-number-inp' placeholder='000' maxLength={3} /> <span className='mesure-category'>Kg</span>
                   </div>
                   <p className='entervalue-text'>Enter value</p>
                  </div>
                </div>
              </div>
            </div>
            <ul className='previous-nextbtns'>
              <li>
                <NavLink to={"/electricitycalculation"} className="prevbtn-data"><div className='previous-btn'>
                  <i class="fas fa-arrow-left"></i>
                </div></NavLink>
              </li>
              <li>
                <NavLink onClick={handleSubmit} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
              </li>
            </ul>

          </div>


        </div>
      </div>
    </>
  )
}

export default FuelCalculator