import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Axios from './../../../Utils/AxiosConfig';
import { DotLoader } from "react-spinners";
import 'toastr/build/toastr.css';
import ApiNames from './../../../Constants/ApiNames';


const CountrySelect = () => {
    let countryInfo
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countriesData, setCountriesData] = useState([]);
    const [countryData, setCountryData] = useState(null); // State to hold data for the selected country
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const getcountries = () => {
        setLoading(true)
        const url = ApiNames.Cerohero_Countries;
        Axios.get(url)
            .then((res) => {
                setLoading(false)
                setCountriesData(res.data.countries); // Remove this line
                res.data.countries.forEach((value) => {

                    if (value.name === countryInfo) {
                        console.log(value)
                        setSelectedCountry(value.id);
                        localStorage.setItem("countryName", value.name)
                        localStorage.setItem("countryValue", value.cie)
                        localStorage.setItem("description", value.description)
                        localStorage.setItem("answer", value.answer)
                        localStorage.setItem("countryId", value.id)
                        setSelectedCountry(value.id);
                        console.log(countryData);
                    }
                })

            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        Axios.get('https://ipapi.co/json/').then(async (value) => {
            console.log(value)
            countryInfo = value.data.country_name

        }).catch((err) => {
            console.log("err", err)
        })
    }, [])

    useEffect(() => {
        getcountries();
    }, []);
    const override = {
        display: "block",
        margin: "20px auto",
        textAlign: "center",
        borderColor: "#025F5C",
    };


    let handleSelectChange = async (event) => {

        const selectedCountryId = event.target.value;
        setSelectedCountry(selectedCountryId);
        localStorage.setItem("countryId", selectedCountryId)
        console.log(selectedCountryId);

        // Find the selected country object from Countries
        countriesData.forEach((value) => {

            if (value.id === parseInt(selectedCountryId)) {
                setCountryData(value);
                console.log(value)
                localStorage.setItem("countryName", value.name)
                localStorage.setItem("countryValue", value.cie)
                localStorage.setItem("description", value.description)
                localStorage.setItem("answer", value.answer)
            }
        })

    };




    function countrynext() {
        if (!selectedCountry) {
            alert("Please select a country.");
        } else {
            navigate(`/doyouknow/${selectedCountry}`);
        }
    }

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


    return (
        <>
            <div className="calculations-content">
                <div className="container">
                    <ul className="calcularor-navbar">
                        <li>
                            <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
                            </div></NavLink>
                        </li>
                    </ul>

                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="country-content">
                                {loading ? ( // Show loader if loading is true
                                    <DotLoader color="#025F5C" loading={loading} css={override} size={50} />
                                ) : (
                                    <form className="country-forms">
                                        <div className="mb-3">
                                            <label className="form-label which-country-para">
                                                Which country do you
                                                live in?
                                            </label>


                                            <select
                                                className="form-select countrycelet-data"
                                                value={selectedCountry}
                                                onChange={handleSelectChange}
                                                aria-label="Default select example"
                                            >

                                                {countriesData.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}

                                            </select>

                                        </div>
                                        <button
                                            type="button"
                                            className="next-btn"
                                            onClick={countrynext}
                                        >
                                            Next
                                        </button>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CountrySelect;
