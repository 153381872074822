import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';

const Ourway = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    const location = useLocation();
    // useEffect(() => {
    //     window.scroll({
    //         top: 0,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    // }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = ApiNames.Subscribe_Newsletter;
        const body = {
            email: subscribemail,
        };
    
        Axios.post(url, body)
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };

    useEffect(() => {
        const hash = location.hash;
        if(hash){
         const id =hash.substring(1);
         const element = document.getElementById(id);
         if(element){
           element.scrollIntoView({
             behavior:"smooth"
           });
         }
        }
       
       }, [location.hash])
    return (
        <>
          <Helmet>
                <title>
                Our Why
                </title>
            </Helmet>
            <div className="ourway-main">
                <div className="ourway-herosection">
                    <div className="container">
                        <div className='ourway-hero'>
                            <h3 className='ourway-title'>{t('ourway.Our Why')}</h3>
                            <p className='ourway-subtitle'>{t('ourway.Unveiling the Heartbeat')}</p>
                        </div>

                    </div>

                </div>
                <div className="ourway-aboutus" id="aboutdata">
                    <div className="container">
                        <div className="aboutus-data">
                            <span className="about-top">
                            {t('ourway.About Us')}
                            </span>
                            <h3 className="aboutus-title">Empowering Eco-Consciousness with Innovation and Teamwork</h3>
                            <p className="aboutus-para">At the heart of innovation and environmental awareness, Cero Hero emerges as a pivotal platform for impactful and sustainable endeavours. Championed by the collective efforts of the Cero Hero team and guided by the vision of our founder, Sai Teja, we leverage cutting-edge technology to forge a path where individual actions contribute to a broader sustainability journey. With a unified vision and dedication to the planet, our team harnesses the potential of innovation with a heartfelt commitment. This collaborative venture goes beyond mere individual accomplishments; it represents a communal space crafted to facilitate active participation in sustainability. Cero Hero transcends the concept of an application — it embodies a communal belief in integrating eco-consciousness effortlessly into our everyday lives, nurtured through innovative technology and shared efforts.</p>

                        </div>

                    </div>
                </div>
                <div className="missionvision-section" id='mission&vision'>
                    <div className="container">
                        <div className="missionvision-top">
                            <h3 className="missionvision-title">{t('ourway.Mission & Vision')}</h3>
                            <p className="missionvision-para">{t('ourway.Crafting a Future')}</p>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="missin-vision">
                                    <div className="mv-image">
                                        <img className="mv-image-inside" src="../images/ourway/mv-image-1.png" alt="no-data" />
                                    </div>
                                    <div className="missionvision-body">
                                        <h3 className="missionvision-body-title">{t('ourway.Our Mission')}</h3>
                                        <p className="missionvision-body-para">"We are on a mission to reduce individual carbon emissions of every user by 50% and help them on their journey towards Net Zero."</p>
                                        {/* <p className="missionvision-body-para">{t('ourway.We aim to empower')}</p> */}
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="missin-vision">
                                    <div className="mv-image">
                                        <img className="mv-image-inside" src="../images/ourway/mv-image-2.png" alt="no-data" />
                                    </div>
                                    <div className="missionvision-body">
                                        <h3 className="missionvision-body-title">{t('ourway.Our Vision')}</h3>
                                        <p className="missionvision-body-para">"To be the world's leading resource for sustainability education and action,
                                        empowering individuals and businesses to achieve Net Zero emissions and build a more sustainable future."</p>
                                        {/* <p className="missionvision-body-para">{t('ourway.At ZeroHero')}</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="ourway-ourjourney">
                    <div className="container">
                        <div className="ourjourney-data">
                            <span className="ourjourney-top">
                            {t('ourway.Our Journey So Far')}
                            </span>
                            <h3 className="ourjourney-title">{t('ourway.ZeroHero - A')}</h3>
                            <p className="ourjourney-para mb-2">The team at Cero Hero represents our collective response to the global sustainability crisis. Inspired by  commitment and our shared values, we recognized the need for a platform that makes sustainability accessible and rewarding. We worked together to transform this vision into reality, making every individual a potential hero for our planet.</p>
                            <p className="ourjourney-para">Our journey from a budding concept to a flourishing initiative has been filled with collective learnings, community engagement, and significant impacts. As a team, we are committed to continuously evolving, innovating, and pushing the boundaries to make sustainability an achievable, accessible, and rewarding journey for everyone.</p>
                            {/* <p className="ourjourney-para">{t('ourway.ZeroHero, Sai')}</p>
                            <p className="ourjourney-para">{t('ourway.Our journey, from')}</p> */}

                        </div>

                    </div>
                </div>
                <div className="subscribe-content">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Ourway