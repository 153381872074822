import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';

const Privacy = () => {
  const handleDownload = () => {
    // Assuming 'prd.pdf' is the path to your PDF file
    const pdfPath = '../../files/privacy.pdf';

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'Cerohero_privacy_policy.pdf'; // Set the filename for the download

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  }, []);
  return (
    <>
      <div className="terms-conditions">
        <div className="container">
          <div className="terms-inside">
            <h3 className="terms-title">Privacy Policy</h3>
            <p className="terms-para">Welcome to Cero Hero. We prioritize your right to privacy and the security of your personal information. If you have any queries or concerns regarding our policy or practices related to your personal information, please reach out to us at <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a>  </p>
            <p className="terms-para">When you use our services or visit the Cero Hero mobile application, you entrust us with your personal information. In this privacy policy, our goal is to be as transparent as possible about the information we collect, how we use it, and the rights you have in connection with it. We encourage you to take the time to carefully study this privacy policy, as it holds significant information. If there are any clauses in this privacy statement with which you disagree, we urge you to bring them to our attention. </p>
            <p className="terms-para">Your decision to provide us with your personal information is important. Therefore, we recommend that you read this privacy policy thoroughly before making that decision.  </p>

            <h4 className="terms-subtitle">Sharing of Information  </h4>
            <p className="terms-para">When you create an account on our application, show interest in obtaining more information about us or our products and services, engage in activities such as posting messages on our online forums or participating in giveaways, contests, or competitions, or reach out to us through other means, we gather the personal information that you willingly provide to us. </p>
            <p className="terms-para">The personal information we collect is influenced by your usage of our Apps and interaction with us, as well as the products and features you choose to utilize.  The following is a list of the types of personal data we could gather:  </p>

            <h4 className="terms-subtitle">What personal data do we collect? </h4>
            <p className="terms-para">We collect and process the following data: </p>

            <h4 className="terms-subtitle">Personal Information  </h4>

            <ol type=".">
              <li>
                <p className="terms-para">First name, last name, ID, email address, social network account information, profile picture, and similar details provided during account sign-up. </p>
              </li>
              <li>
                <p className="terms-para">Carbon Footprint calculator data, which helps estimate and display your lifestyle carbon emissions based on factors like how you live, travel, and consume. </p>
              </li>
              <li>
                <p className="terms-para">Usage data, including information on how our website or ads are accessed and used (e.g., IP address, browser type, and version, visited pages on our website, unique device identifiers, and other diagnostic data). </p>
              </li>

            </ol>
            <h4 className="terms-subtitle">Collection of Device ID </h4>
            <p className="terms-para">Cero Hero collects and stores the unique identifier of your mobile device, commonly known as the Device ID. This identifier distinguishes your device from others, contributing to a personalized and seamless user experience.  </p>
            <p className="terms-para">We are dedicated to safeguarding your privacy, particularly when it comes to collecting your device ID for unique user identification purposes within the Cero Hero application. This information is used exclusively to personalize your experience and improve our services. Your device ID is treated with the utmost confidentiality and is not shared with third parties. By using Cero Hero, you implicitly consent to the collection and use of your device ID as outlined in this policy. We prioritize data security and adhere to relevant privacy laws.  </p>
            <h4 className="terms-subtitle">Usage of Social Media Login Data  </h4>
            <p className="terms-para"> We offer users the option to log in using their social media accounts, and by selecting this option, you agree to provide us access to specific personal information associated with your social media account. The collection and utilization of this social media login data are solely for the purpose of authenticating your account and ensuring a seamless user experience. </p>
            <p className="terms-para">Within your account settings, you have the option to disconnect your social media accounts from our service. Additionally, you can request access to the social media login data collected from your account, subject to any legal obligations or restrictions. If you decide to permanently delete your social media login data from our systems, please be aware that this action may result in the deletion of your account. For further assistance, please contact our support team. Note that certain information may be retained in backups or archives for a limited period.  </p>

            <h4 className="terms-subtitle">User Content and Behaviour Information </h4>
            <p className="terms-para">We gather information on your usage of the Cero Hero App, including interactions with content, viewed advertisements, watched videos, and any feedback on content. We also analyze your preferences, such as hobbies, gender, and age, to customize the content. This information may be used to deliver personalized advertisements and inform you about new services and opportunities as appropriate. </p>

            <h4 className="terms-subtitle">Technical Information  </h4>
            <p className="terms-para">To provide our services, we process data as technically necessary. This includes server logs, firewall logs, and other data from our hosting partner AWS. Basic connection information, such as the IP address of the terminal device and the geo-time of access, is retained in connection with these logs. </p>
            <p className="terms-para">During the initial setup of your user account, we collect information about your device location to better identify where our consumers are situated. This is crucial for scheduling Cero Hero Events near our interested customers. For specific activities requiring visual content capture or recording, we may request access to your camera. Rest assured, we prioritize data security and adhere to applicable privacy laws throughout these processes.  </p>

            <h4 className="terms-subtitle">Movement data (Healthkit)  </h4>
            <p className="terms-para">We use this data to confirm that you are moving physically, and we then award the carbon offset and Cero credits based on this validated information, for the Outdoor activity challenge.   </p>

            <h4 className="terms-subtitle">Your Choices   </h4>
            <p className="terms-para">At Cero Hero, we are committed to ensuring the privacy and security of your personal information. The data collected through our carbon footprint calculator, including details about your electricity consumption, dietary preferences, transportation habits, heating and cooking fuel, and household size, is used solely for the purpose of calculating your individual carbon footprint. We assure you that we do not misuse or share your specific choices for any other purposes. Your data is treated with the utmost confidentiality, and we adhere to stringent security measures to safeguard your information.  </p>
            <p className="terms-para">Our primary goal is to empower you with insights into your environmental impact, promoting awareness and sustainable choices. For further details on how we handle and protect your data. </p>
            <h4 className="terms-subtitle">General  </h4>
            <p className="terms-para">Regarding your personal information, you retain certain options. If you have previously given consent for the processing of your personal information, you can withdraw it at any time by contacting us as indicated below. Even if you opt not to have your information collected, we may still collect it for purposes such as tracking your usage of our services and other lawful reasons outlined above. </p>
            <h4 className="terms-subtitle">Newsletter</h4>
            <p className="terms-para">When you subscribe to our newsletter, we process your name and email to send it to you. This processing is based on your consent, which you can withdraw at any time by clicking the unsubscribe link in our newsletters. </p>
            <h4 className="terms-subtitle">Email and Telephone Communications </h4>
            <p className="terms-para">To cease receiving future emails, utilize the unsubscribe link at the bottom of each email you receive from us. However, please note that you may not be able to unsubscribe from certain non-promotional communications about us and our services, such as notifications or updates to our Terms or this Privacy Policy. If you prefer not to receive text communications, you can choose to opt out by following the instructions in the text message or contacting us through other means. </p>
            <h4 className="terms-subtitle">Mobile Devices </h4>
            <p className="terms-para">Through our mobile application, we may send you push notifications. You can choose not to receive these communications by adjusting the settings on your mobile device. Additionally, if you use our mobile applications, we may collect location-based data from you. You have the option to opt out of this collection by modifying the settings on your mobile device. Rest assured, we respect your choices and prioritize your control over your personal information. </p>
            <h4 className="terms-subtitle">How we use your information </h4>
            <p className="terms-para">In order to carry out our agreement with you and offer you our services, such as: Managing your information and accounts, we use your information for a range of business objectives, including:  </p>
            <ol type=".">
              <li>
                <p className="terms-para">Granting access to certain components, features, and locations of our services; </p>
              </li>
              <li>
                <p className="terms-para">Engaging in actions to confirm or maintain the quality or safety of a service or device; communicating with you about your account, activity on our services, and changes to our policies;  </p>
              </li>
              <li>
                <p className="terms-para"> Delivering marketing, analytics, and advertising services; handling transactions and applications; and </p>
              </li>

              <li>
                <p className="terms-para">Letting you sign up for activities. </p>
              </li>

            </ol>
            <h4 className="terms-subtitle">Analyze and enhance our services in accordance with our legitimate interests, which include:  </h4>

            <ol type=".">
              <li>
                <p className="terms-para">Detecting security incidents, preventing malicious, deceptive, fraudulent, or unlawful activities, and bringing cases against individuals involved; </p>
              </li>
              <li>
                <p className="terms-para">Doing research to advance and demonstrate technology; </p>
              </li>
              <li>
                <p className="terms-para">Improving the performance, resilience, dependability, or efficiency of products, services, marketing strategies, or security protocols;  </p>
              </li>
              <li>
                <p className="terms-para"> Enhancing, improving, or modernizing our services or equipment;  </p>
              </li>
              <li>
                <p className="terms-para"> New product and service development, internal quality assurance, and  </p>
              </li>
              <li>
                <p className="terms-para">Protecting against fraud and confirming your identity; </p>
              </li>
              <li>
                <p className="terms-para"> Debugging to find and fix flaws that prevent the desired functionality from being used as intended; </p>
              </li>
              <li>
                <p className="terms-para"> Upholding our rules and regulations; and</p>
              </li>

              <li>
                <p className="terms-para">Observing our legal duties, safeguarding your important interests, or as may be necessary</p>
              </li>

            </ol>
            <h4 className="terms-subtitle">Cookies  </h4>
            <p className="terms-para">In order to track and analyze how you use the Cero Hero Application and Cero Hero website, we and our vendors and service providers employ cookies (sometimes referred to as "Cookies"). We use these cookies to improve our services, and your usage of the Cero Hero App and Website, and to deliver more relevant advertising to you on the app. You agree to our usage of cookies if you use the Cero Hero app or website.  <br/>

By modifying the settings of your browser, you might be able to reject or deactivate Cookies. Please refer to the instructions supplied by your browser as each browser is unique. Please be aware that in order to reject or deactivate particular Cookie types, you might need to take extra measures.  </p>
           
<h4 className="terms-subtitle">How we use technologies for automatic collection </h4>
            <p className="terms-para">Cookies, pixel tags, and other technologies may be used by us as well as by third parties that provide content, advertising, or other functionality on the services to automatically gather information. These are the broad categories in which we employ these technologies </p>
            <h4 className="terms-subpara">Operational Demands and Obligations</h4>
            <p className="terms-para">This includes technologies that grant you access to our services, software, and tools needed to detect abnormal site behavior, stop fraud, enhance security, or give you access to our features;  </p>
            <h4 className="terms-subpara">Related to performance</h4>
            <p className="terms-para"> Technologies may be used by us to evaluate the effectiveness of our services, including as part of our analytic procedures to assist us in better comprehending how our customers utilize our services;  </p>
            <h4 className="terms-subpara">Relationship to Function</h4>
            <p className="terms-para"> We could employ technologies that give you more functionality when using or accessing our services. This might be recognizing you when you join into our services or keep track of your preferences, interests, or previously watched content; related to advertising or targeting. To provide information, including advertisements tailored to your interests, on our services or on outside websites, we may utilize first-party or third-party technologies.  </p>
            <h4 className="terms-subpara">Tracking across devices</h4>
            <p className="terms-para"> Your online activities may be monitored across several websites, devices, or applications. As an illustration, we may try to compare the surfing habits you have on your laptop and mobile device. Our technology partners may exchange information, including user name, and geographic location only. </p>
<h4 className="terms-subtitle">Notice regarding social media platforms, software development kits, and third-party Websites </h4>
            <p className="terms-para">Other websites may mention or link to our website or other services, and the services themselves may contain connections to other websites. We do not have any control over such other websites. We advise all of our users to read the privacy statements for every website and service they visit. The privacy policies or content of such other websites or applications are not supported, reviewed, or approved by us, and we are not liable for them. You do so at your own risk while using these other websites or applications. <br/>
Blogs, forums, social networking pages, and private messaging tools might all be a part of our services. You accept the risk that the personal information you supply may be misused by utilizing such Services.   </p>

<h4 className="terms-subtitle">Developing and analyzing our website </h4>
            <p className="terms-para">We collect and process data via cookies to develop the functionality, usability, and overall awesomeness of our website by analyzing its usage and occurring problems. Some cookies are always necessary for the website to function and some cookies are used for statistical purposes. </p>
            <p className="terms-para">We use Google Analytics to collect information about the usage of our website. We use this information to create reports of the usage and to develop the website.  </p>
<h4 className="terms-subtitle">Information disclosure to third parties </h4>
            <p className="terms-para">We don't disclose your personal information to anybody else outside what is stated below.  </p>
<h4 className="terms-subtitle">Service companies </h4>
            <p className="terms-para">Any private information we have about you that we get may be disclosed to our outside service providers. IT and associated services, information and services, payment processors, customer service providers, and suppliers to support the operation of the services, are among the types of service providers (processors) with which we entrust personal information.  </p>
<h4 className="terms-subtitle">Business associates </h4>
            <p className="terms-para">To business partners with whom we jointly provide products or services, we could disclose personal information. In such circumstances, our business partner's name will also be shown.  </p>
<h4 className="terms-subtitle">Affiliates</h4>
            <p className="terms-para">Personal data could be shared with our linked businesses.  </p>
<h4 className="terms-subtitle">Partners in advertising </h4>
            <p className="terms-para">Through our services, could permit third-party advertising partners to set up technologies and other tracking mechanisms to gather data about your activity and your device (such as your IP address, mobile identifiers, visited pages, location, and time of day). With our third-party advertising partners, we may additionally mix and share this information with other information (such as demographic data and previous purchase history). When you visit third-party websites within their networks, these advertising partners may use this information (together with comparable data acquired from other websites) to serve tailored adverts to you.   </p>
            <p className="terms-para">Online behavioral advertising or "interest-based advertising" are frequent names for this practice. In order to share information with you that may be important, pertinent, helpful, or otherwise of interest to you, we may permit access to additional data that has been gathered by the services. You can follow the guidelines below if you'd rather not give your personal information to third-party advertising partners.  </p>
<h4 className="terms-subtitle"> 
Disclosures made to safeguard us or others </h4>
            <p className="terms-para">If we, in good faith, believe that doing so is necessary or appropriate to: protect your rights, property, or safety; comply with legal process, such as a court order or subpoena; enforce our policies or contracts; recover debts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity, we may access, preserve, and disclose any information we store about you to outside parties. </p>
<h4 className="terms-subtitle">In the event of a merger, sale, or other asset transfer, disclosure is required </h4>
            <p className="terms-para"> 

Your information may be sold or transferred as part of such a transaction if we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, as permitted by law and/or contract.  <br/>

You acknowledge that we may move, process, and keep your information anywhere in the globe, including, but not limited to, the United States or other nations with differing data protection regulations from those in your own country. We have taken the necessary precautions to ensure that your personal information is safeguarded, and we demand that our partners and third-party service providers have. </p>
<h4 className="terms-subtitle">Billing and Payment Information Responsibility </h4>
            <p className="terms-para">Cero Hero underscores the importance of user privacy and security. It is crucial to note that all billing information, and payment details, are exclusively managed and safeguarded by the respective Third Party Aggregators (TPAs) and their associated websites within our marketplace. Cero Hero explicitly disclaims any responsibility for the security or handling of such sensitive information. </p>
<h4 className="terms-subtitle">User-Initiated Product Purchases and Cero Credits Redemption </h4>
            <p className="terms-para">To offer users maximum flexibility and eco-conscious choices, Cero Hero allows all users the freedom to purchase their chosen products independently. This can be achieved by utilizing their accumulated Cero Credits by selecting the "Redeem" option, enabling the conversion of credits into an equivalent monetary amount that is equal to the value of the product. By doing so, users can make personalized, environmentally conscious decisions while enjoying the tangible benefits derived from their earned Cero Credits. </p>
<h4 className="terms-subtitle">Your Privacy Rights  </h4>
            <p className="terms-para">You may be entitled to the following, subject to current law: According to legal regulations, you have access to and portability over your personal data. Additionally, you might have the right in specific circumstances to obtain or request the transfer of your electronic personal data to another person.  <br/>

In cases where your personal information is missing or wrong, request a correction. In some circumstances, we may offer self-service options so you may update your personal information, or we may direct you to the person in charge of your personal information who can make the necessary changes. <br/>

You have the option to request the deletion of your personal information, subject to certain conditions. Additionally, you can request to limit or object to the processing of your personal data and choose whether to consent or dissent to the sale of your personal data to third parties, if permitted by law. <br/>

Please log into your account or get in touch with us as specified below if you want to make use of any of these rights. Such requests will be handled in accordance with relevant legal requirements. Before granting your privacy request, we will take precautions to confirm your identity in order to safeguard your privacy. For example, we may require you to sign into your account or confirm your email.  <br/>

To delete your account and any personal information we hold on you. You can exercise your right to delete your Cero Hero account and the personal information attached in the application itself by this process.  On the home screen {'>'} Profile {'>'}Settings {'>'} Delete account.   </p>
<h4 className="terms-subtitle">Data Security </h4>
            <p className="terms-para">We employ robust data security measures in accordance with GDPR, Personal Data Protection Act (PDPA) compliances, Digital Personal Data Protection Bill (DPDP), and Personal Data Protection Law (PDPL) to ensure the safe handling and storage of your data within a secure environment. We take precautions to guarantee the security and adherence to this Privacy Policy of the handling of your information. We take appropriate technical and organizational measures to protect personal data against unauthorized access and accidental or unlawful destruction, alteration, disclosure, transfer, or all other unlawful forms of processing. We process all personal data confidentially.  </p>
            <p className="terms-para">The right of access to personal data is limited so access rights are provided only to persons who require access in order to be able to perform their duties. We do not process any data manually and we do not have a manual register.  </p>
            <p className="terms-para">You agree that we may contact you electronically on security, privacy, and operational problems pertaining to your use of the services by utilizing the service or by sending us personal information. If we become aware of a security system breach, we may try to alert you electronically by sending you an email, publishing a notice on the services, or sending you a letter.  No system is completely safe, thus we are unable to guarantee or promise the security of any information you send to us. To the maximum extent authorized under the relevant law, we disclaim responsibility for disclosure that was made accidentally. </p>
           
<h4 className="terms-subtitle">Children’s Policy  </h4>
            <p className="terms-para">Children under the age of 14 are not intended for use with the Services, and we do not intentionally collect personal information from them. You can get in touch with us as described below if you discover that your kid has given us personal information without your permission. If we become aware that we have gathered any personal data in contravention of the law, we shall act right away to erase that data and deactivate the child's account.  </p>
<h4 className="terms-subtitle">Contact Information  </h4>
            <p className="terms-para">Please get in touch with us at the following given address if you have any inquiries regarding our privacy practices or this Privacy Policy, or if you want to submit a request to exercise your rights as described in this Privacy Policy.  </p>
            

            <h4 className="terms-subtitle">Cero Hero   </h4>
            <p className="terms-para pb-0">E-mail: <a href="mailto:info@cerohero.com" class="contact-email">info@cerohero.com</a>  </p>
            <p className="terms-para">Website: <NavLink to="/landing" className="terms-website">www.cerohero.com </NavLink>  </p>
            <p className="terms-para">3rd Floor, Gowri Arcade, HIG-A-85,  <br />
              Jayabheri Enclave, Gachibowli, Hyderabad, Telangana 500032  </p>
          </div>
          <NavLink className="download-pdf" onClick={handleDownload}><img className="pdf-icons" src="../../images/terms/pdf-icon.png" alt="no-data" />Download</NavLink>

        </div>

      </div>
    </>
  )
}

export default Privacy