import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'


const ElectricityCalculator = () => {
  const [electricityValue, setElectricityValue] = useState();
  const [solarValue, setSolarValue] = useState();
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setElectricityValue(value);
    }
  };
  const handleChangeOne = (event) => {
    const { value } = event.target;
  
    if (/^\d*$/.test(value)) {
      setSolarValue(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let defaultValue = 0
    let defaultSolar =0
    if (electricityValue === undefined || electricityValue === null || electricityValue === '') {
      defaultValue = 0
    }else{
      defaultValue = electricityValue
    }
    if (solarValue === undefined || solarValue === null || solarValue === '') {
      defaultSolar = 0
    }else{
      defaultSolar = solarValue
    }
    localStorage.setItem('electricityValue',defaultValue)
    localStorage.setItem('solarValue',defaultSolar)
    window.location.href = `/fueluse`;
    // You can perform further actions with the value here
  };
  return (
    <>
      <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='electiricity-content'>
            <div className='electiricity-top'>
              <h3 className='electiricity-title'>Household electricity usage</h3>
              <p className='electiricity-titlewhat'>Enter your household's total annual electricity usage.</p>
            </div>
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <div className="card electricity-card" >
                  <div className='electricity-card-image'>
                    <img src="./images/calculator/electricity-img-1.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">

                    <p className="card-text electiricity-para electiricity-para2">
                    How many units of electricity (kWh) do you use in a year?
                    </p>
                    <div className='electricity-number'>
                      <input type='tel' className='electricity-number-inp' value={electricityValue}
                        onChange={handleChange} placeholder='000000' maxLength={6} /> <span className='mesure-category'>kWh</span>
                    </div>
                    <p className='entervalue-text'>Enter value</p>
                  </div>
                </div>

              </div>
              <div className='col-md-4' style={{ borderLeft: '1px solid #67E24D' }}>
                <div className="card electricity-card" >
                  <div className='electricity-card-image'>
                    <img src="./images/calculator/electricity-img-2.webp" className="card-img-top card-image-data" alt="..." />
                  </div>
                  <div className="card-body electiricity-body">

                    <p className="card-text electiricity-para electiricity-para2">
                    Of your total electricity usage, what % is from renewable sources, e.g., rooftop solar panels? 
                    </p>
                    <div className='electricity-number'>
                      <input value={solarValue}
                        onChange={handleChangeOne} type='tel' className='electricity-number-inp' placeholder='00' maxLength={2} /> <span className='mesure-category'>%</span>
                    </div>
                    <p className='entervalue-text'>Enter value</p>
                  </div>
                </div>
              </div>

            </div>
            <ul className='previous-nextbtns'>
              <li>
                <NavLink to={"/household"} className="prevbtn-data"><div className='previous-btn'>
                  <i class="fas fa-arrow-left"></i>
                </div></NavLink>
              </li>
              <li>
                <NavLink to={"/fueluse"}  onClick={handleSubmit} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </>
  )
}

export default ElectricityCalculator