import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import 'swiper/swiper-bundle.css';
// import { Navigation } from 'swiper';

const Enterprise = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Enterprise</title>
      </Helmet>
      <div className="enterprise-page-top">
      <div className="enterprise-herosection">
          <div className="container">
            <div className="enterprise-hero">
              <h3 className="enterprise-title">
              Empowering Sustainable Futures Through 
              Comprehensive Carbon Management
              </h3>
              <p className="enterprise-subtitle">
              Cero Hero’s Enterprise Solutions for a Greener Tomorrow
              </p>
              <div className="request-bttn">
                <NavLink to="/contactus" className="request-usBtn">Request a Quote</NavLink>
              </div>
              <div className="enterprise-bgimage">
                  <img src="../images/enterprise/enterprise-bg.png" alt="Enterprise Solutions"/>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="enterprise-herosection">
          <div className="container">
            <div className="enterprise-hero">
              <h3 className="enterprise-title">
                Empowering Enterprises for a Sustainable Future
              </h3>
              <p className="enterprise-subtitle">
                Cero Hero is your enterprise companion, dedicated to fostering
                climate literacy and guiding your employees towards a
                sustainable path. We believe that sustainability is a collective
                responsibility, where both the company and its employees play
                crucial roles in driving meaningful change. With Cero Hero,
                embark on a journey towards a greener future together.
              </p>
            </div>
          </div>
        </div> */}
        <div className="enterprisework-section">
                    <div className="container">
                        <div className="enterprisework-top">
                            <h3 className="enterprisework-title">How It Works</h3>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="enterprise-work">
                                    <div className="ew-image"> 
                                        <img className="ew-image-inside" src="../images/enterprise/icon12.png" alt="no-data" />
                                    </div> 
                                    <div className="enterprisework-body">
                                        <h3 className="enterprisework-body-title">Download and Onboard</h3>
                                        <p className="enterprisework-body-para">Quickly integrate our platform into your existing systems with our plug-and-play setup. Seamless onboarding ensures you get started without disruption.</p>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="enterprise-work">
                                    <div className="ew-image">
                                        <img className="ew-image-inside" src="../images/enterprise/icon13.png" alt="no-data" />
                                    </div>
                                    <div className="enterprisework-body">
                                        <h3 className="enterprisework-body-title">Set Targets</h3>
                                        <p className="enterprisework-body-para">Collaborate with our sustainability experts to define ambitious yet achievable goals that align with your organization’s sustainability objectives.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="enterprise-work">
                                    <div className="ew-image">
                                        <img className="ew-image-inside" src="../images/enterprise/icon14.png" alt="no-data" />
                                    </div>
                                    <div className="enterprisework-body">
                                        <h3 className="enterprisework-body-title">Track Progress</h3>
                                        <p className="enterprisework-body-para">Use real-time data and analytics to monitor carbon emissions, identify areas for improvement, and adjust strategies to meet targets effectively.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="enterprise-work">
                                    <div className="ew-image">
                                        <img className="ew-image-inside" src="../images/enterprise/icon15.png" alt="no-data" />
                                    </div>
                                    <div className="enterprisework-body">
                                        <h3 className="enterprisework-body-title">Incentivize Action</h3>
                                        <p className="enterprisework-body-para">Engage your supply chain and workforce by offering rewards and recognition for outstanding sustainability contributions, driving organization-wide participation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="enterprise-work">
                                    <div className="ew-image">
                                        <img className="ew-image-inside" src="../images/enterprise/icon16.png" alt="no-data" />
                                    </div>
                                    <div className="enterprisework-body">
                                        <h3 className="enterprisework-body-title">Report and comply</h3>
                                        <p className="enterprisework-body-para">Generate comprehensive reports that are stakeholder-ready and ensure your business remains compliant with global environmental regulations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
          <div className="features-benefits">
            <div className="container">
            <h3 className="corebenefits-title">
                 Core Features & Benefits
              </h3>
            <Swiper
                slidesPerView={3}
                spaceBetween={20}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
               
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  
                  762: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                
                }}
                modules={[Autoplay]}
                
                className="mySwiper"
              >
              <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon7.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Customizable Enterprise Platforms</h3>
                    <p className="makinglist-para">
                    Tailor our platform to meet your business's unique needs with adaptable features, ensuring alignment with your operational objectives.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon8.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Scalable Solutions</h3>
                    <p className="makinglist-para">
                    Designed for growing enterprises, Cero Hero's solutions scale seamlessly as your organization expands.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon9.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Advanced Analytics & Reporting</h3>
                    <p className="makinglist-para">
                    Unlock insights with real-time data analysis, empowering decision-making with accurate and actionable information.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon10.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Streamlined Integration</h3>
                    <p className="makinglist-para">
                    Easily integrate Cero Hero with your existing enterprise systems for a smooth, disruption-free implementation.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon11.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Security & Compliance</h3>
                    <p className="makinglist-para">
                    Enterprise-grade security protocols to safeguard your data and ensure regulatory compliance across various industries.
                    </p>
                  </div>
                </SwiperSlide>
                </Swiper>
                </div>
                </div>
              {/* <Swiper
                slidesPerView={3}
                spaceBetween={20}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
               
                breakpoints={{
                  // when window width is >= 640px
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  
                  762: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                
                }}
                modules={[Autoplay]}
                
                className="mySwiper"
              >
              <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon1.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Climate Literacy</h3>
                    <p className="makinglist-para">
                      Equip employees with essential climate knowledge through
                      engaging modules.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon2.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">
                      Emission Tracking and Monitoring
                    </h3>
                    <p className="makinglist-para">
                      Monitor organizational carbon emissions with real-time
                      data and insights.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icnn3.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">
                      Reward & Incentivising Mechanism
                    </h3>
                    <p className="makinglist-para">
                      Recognize and reward sustainable actions and milestones.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                         src="../images/enterprise/icon4.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">Sustainable Choices</h3>
                    <p className="makinglist-para">
                    Provide a curated list of eco-friendly options
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon5.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">
                    Enterprise dashboard
                    </h3>
                    <p className="makinglist-para">
                    Track and analyze employee sustainability efforts with an intuitive dashboard.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="making-lists">
                    <div className="logo-list">
                      <img
                        className="making-list-image"
                        src="../images/enterprise/icon6.png"
                        alt="no-data"
                      />
                    </div>
                    <h3 className="makinglist-title">
                    Community & Support
                    </h3>
                    <p className="makinglist-para">
                    Join a supportive community and access ongoing assistance for sustainability initiatives.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper> */}
              {/* <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img
                      className="making-list-image"
                      src="../images/landing/ourmission-logo-1.svg"
                      alt="no-data"
                    />
                  </div>
                  <h3 className="makinglist-title">
                  Climate Literacy
                  </h3>
                  <p className="makinglist-para">
                  Equip employees with essential climate knowledge through engaging modules.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img
                      className="making-list-image"
                      src="../images/landing/ourmission-logo-2.svg"
                      alt="no-data"
                    />
                  </div>
                  <h3 className="makinglist-title">
                  Emission Tracking and Monitoring
                  </h3>
                  <p className="makinglist-para">
                  Monitor organizational carbon emissions with real-time data and insights.
                  </p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img
                      className="making-list-image"
                      src="../images/landing/ourmission-logo-3.svg"
                      alt="no-data"
                    />
                  </div>
                  <h3 className="makinglist-title">
                  Reward & Incentivising Mechanism
                  </h3>
                  <p className="makinglist-para">
                  Recognize and reward sustainable actions and milestones.
                  </p>
                </div>
              </div>
            </div> */}
          <div className="enterprisebill-section">
                    <div className="container">
                        <div className="enterprisebill-top">
                            <h3 className="enterprisebill-title">Enterprise Billing</h3>
                            <p className="enterprisebill-para">Tailored pricing designed for enterprises of all sizes</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="enterprisebill-plan">
                                    <div className="enterprisebill-body">
                                        <h3 className="enterprisebill-body-title">Basic Enterprise Plan</h3>
                                        <p className="enterprisebill-body-para">For companies beginning their sustainability journey.</p>
                                        <ul className="enterprisebill-body-list">
                                          <li>Up to 1000 metric tons of carbon tracking</li>
                                          <li>Basic reporting tools</li>
                                          <li>Standard compliance support</li>
                                          <li>Email & phone support</li>
                                    </ul>
                                  </div>
                                  <div className="bookdemo-bttn">
                                      <NavLink to="/contactus" className="bookdemo-usBtn">Book Demo</NavLink>
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                                <div className="enterprisebill-plan">
                                    <div className="enterprisebill-body">
                                        <h3 className="enterprisebill-body-title">Advanced Enterprise Plan</h3>
                                        <p className="enterprisebill-body-para">For enterprises with higher carbon emissions and complex sustainability needs.</p>
                                        <ul className="enterprisebill-body-list">
                                          <li>Up to 10,000 metric tons of carbon tracking</li>
                                          <li>Advanced real-time tracking and analytics</li>
                                          <li>Customizable reporting for stakeholders</li>
                                          <li>Dedicated compliance and regulatory support</li>
                                          <li>API integration with existing enterprise systems</li>
                                        </ul>
                                    </div>
                                    <div className="bookdemo-bttn">
                                      <NavLink to="/contactus" className="bookdemo-usBtn">Book Demo</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="enterprisebill-plan">
                                    <div className="enterprisebill-body">
                                        <h3 className="enterprisebill-body-title">Custom Plan</h3>
                                        <p className="enterprisebill-body-para">For global enterprises with complex and unique requirements.</p>
                                        <ul className="enterprisebill-body-list">
                                          <li>Unlimited carbon tracking</li>
                                          <li>Advanced multi-level data analytics</li>
                                          <li>Full customization of platform features</li>
                                          <li>Dedicated sustainability experts</li>
                                          <li>Comprehensive compliance services for global regulations</li>
                                          <li>Full API and CRM integration</li>
                                        </ul>
                                    </div>
                                    <div className="bookdemo-bttn">
                                      <NavLink to="/contactus" className="bookdemo-usBtn">Book Demo</NavLink>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
          <div className="container">
          <div className="lets-collaborate">
            <div className="lets-collaborate-inside">
              <h3 className="collaborate-title">Let’s Collaborate for a Sustainable Future</h3>
              <p className="collaborate-subtitle">
              Join hands with Cero Hero to take the next step in your company’s sustainability journey. Together, we can drive measurable climate impact, foster transparency, and build a greener, more sustainable future.
              </p>
            </div>
          </div> 
          {/* <div className="get-started">
            <div className="get-start-inside">
              <h3 className="start-title">Get Started Today</h3>
              <p className="start-subtitle">
                Join hands with Cero Hero and lead your company towards a
                sustainable future. Contact us to learn more about our
                enterprise solutions and how we can tailor our services to meet
                your unique needs.
              </p>
              <div className="conact-bttn">
                <NavLink to="/contactus" className="contact-usBtn">Contact Us</NavLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Enterprise;
