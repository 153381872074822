import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { DotLoader } from "react-spinners";


const Sustainability = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    const [allBlogs, setallBlogs] = useState([]);
    let [loading, setLoading] = useState(false);
    function formatDate(dateString) {
        const date = new Date(dateString);
        return format(date, "yyyy-MM-dd");
    }
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        blogs();
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = ApiNames.Subscribe_Newsletter;
        const body = {
            email: subscribemail,
        };

        Axios.post(url, body)
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    console.log(res.data.message);
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };
    const blogs = () => {
        setLoading(true)
        const url = ApiNames.Cerohero_AllBlogs;
        Axios.get(url)
            .then((res) => {
                setLoading(false)
                setallBlogs(res.data.posts);

            })
            .catch((err) => {
                console.log(err);
            });
    };
    const override = {
        display: "block",
        margin: "20px auto",
        borderColor: "#025F5C",
    };
    return (
        <>
            <Helmet>
                <title>
                    Sustainability
                </title>
            </Helmet>
            <div className='sustainability-content'>
                {/* <div className='learningzone-section'>
                    <div className='container'>
                        <div className='learningzone-hero'>
                            <h3 className='learning-title'>{t('sustainability.Welcometo')} <span>{t('sustainability.learningzone')}</span> </h3>
                            <p className='learning-subtitle'>{t('sustainability.embarkon')}</p>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-md-4'>
                                <div className='guide-content'>
                                    <div className='guide-image-data'>
                                        <img className='guilde-image' src='../images/landing/guide-1.png' alt='no-data' />
                                    </div>
                                    <h3 className='guilde-title'>{t('sustainability.Guide')} 1</h3>
                                    <p className='guilde-para'>{t('sustainability.understandingyourcarbon')}</p>

                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='guide-content'>
                                    <div className='guide-image-data'>
                                        <img className='guilde-image' src='../images/landing/guide-2.png' alt='no-data' />
                                    </div>
                                    <h3 className='guilde-title'>{t('sustainability.Guide')} 2</h3>
                                    <p className='guilde-para'>{t('sustainability.sustainableliving')}</p>

                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='guide-content'>
                                    <div className='guide-image-data'>
                                        <img className='guilde-image' src='../images/landing/guide-3.png' alt='no-data' />
                                    </div>
                                    <h3 className='guilde-title'>{t('sustainability.Guide')} 3</h3>
                                    <p className='guilde-para'>{t('sustainability.greeneating')}</p>

                                </div>
                            </div>
                        </div>
                        <NavLink className="explore-button">{t('sustainability.explorebutton')}</NavLink>
                    </div>
                </div> */}

                <div className='tipsandtricks-section'>
                    <div className='container'>
                        <div className='tipsandtricks-content'>
                            <div className='tipsandtricks-hero'>
                                <h3 className='tipsandtricks-title'>{t('sustainability.tipstricks')}</h3>
                                <p className='tipsandtricks-subtitle'>{t('sustainability.smartchoices')}</p>
                            </div>
                            {/* <div className='row justify-content-center'>
                                <div className='col-md-4'>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>1</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>{t('sustainability.swapandsave')}</h3>
                                        <p className='tipsandtricks-inside-para'>{t('sustainability.makingthetransition')}</p>

                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>2</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>{t('sustainability.wastenot')}</h3>
                                        <p className='tipsandtricks-inside-para'>{t('sustainability.creativesimple')}</p>

                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>3</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>{t('sustainability.ecotravel')}</h3>
                                        <p className='tipsandtricks-inside-para'>{t('sustainability.exploringtheworkld')}</p>

                                    </div>
                                </div>
                            </div> */}
                            {/* <NavLink className="tipsandtricks-button">{t('sustainability.getmoretips')}</NavLink> */}
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}

                                pagination={false}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                            >


                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>1</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Advocate for Change</h3>
                                        <p className='tipsandtricks-inside-para'>Engage in community initiatives and policy advocacy to promote environmental sustainability.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>2</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Conscious Traveling</h3>
                                        <p className='tipsandtricks-inside-para'>Travel sustainably by choosing eco-friendly accommodations and experiences.</p>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>3</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Green Your Commute</h3>
                                        <p className='tipsandtricks-inside-para'>Opt for public transportation, biking, or walking to reduce carbon emissions.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>4</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Sustainable Diet Choices</h3>
                                        <p className='tipsandtricks-inside-para'>Prioritize plant-based meals and locally sourced foods to lessen your environmental footprint.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>5</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Mindful Energy Consumption</h3>
                                        <p className='tipsandtricks-inside-para'>Switch to renewable energy sources and use appliances efficiently to conserve energy.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>6</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Eco-friendly Shopping</h3>
                                        <p className='tipsandtricks-inside-para'>Choose products with minimal packaging and support eco-conscious brands.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>7</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Reduce, Reuse, Recycle</h3>
                                        <p className='tipsandtricks-inside-para'>Embrace the three Rs to minimize waste and promote a circular economy.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>8</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Sustainable Fashion</h3>
                                        <p className='tipsandtricks-inside-para'>Support ethical fashion brands and consider second-hand shopping to reduce waste.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='tipsandtricks-inside-content'>
                                        <div className='tipsandtricks-inside-image-data'>
                                            <h3 className='tipsandtricks-inside-image'>9</h3>
                                        </div>
                                        <h3 className='tipsandtricks-inside-title'>Green Gardening</h3>
                                        <p className='tipsandtricks-inside-para'>Cultivate a garden with native plants to support local biodiversity.</p>

                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>

                </div>

                <div className='deepdives-section'>
                    <div className='container'>
                        <div className='deepdives-content'>
                            <div className='deepdives-hero'>
                                <h3 className='deepdives-title'>{t('sustainability.deepdives')}</h3>
                                <p className='deepdives-subtitle'> {/*{t('sustainability.deepsustainability')} <br /> */}
                                    {t('sustainability.forthoseseeking')}</p>
                            </div>

                            {/* <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}

                                pagination={false}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                            >
                                {allBlogs.map((data) => {

                                    return (
                                        <SwiperSlide>
                                            <NavLink style={{ textDecoration: "none" }} to={`/Blogdata/${data.id}`} key={data.id}>
                                                <div className="card article-content" >
                                                    <div className='article-image'>
                                                        <img src={data.image} className="card-img-top article-inside-image" alt="..." />
                                                    </div>
                                                    <div className="card-body article-bodys">
                                                        <h5 className="card-title article-title">{formatDate(data.date)}</h5>
                                                        <p className="card-text article-para">
                                                            {data.title}
                                                        </p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </SwiperSlide>

                                    );
                                })}

                            </Swiper>
                            <NavLink className="deepdives-button">{t('sustainability.readmorebtn')}</NavLink> */}
                            {loading ? <DotLoader
                                color={"#025F5C"}
                                cssOverride={override}
                                loading={loading}
                                size={100}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> :
                                <div className="row">
                                    <div className="col-md-5 pe-lg-4">
                                        <div className="left-data">
                                            <div className="post-section">
                                                <h4 className="popularr-pst">Popular posts</h4>
                                                {allBlogs.slice(0, 7).map((data, index) => {

                                                    return (
                                                        <NavLink className="blogleft-deconone" to={`/Blogdata/${data.id}`} key={data._id}>
                                                            <ul className="Post-top list-unstyled d-flex align-items-center"   >
                                                                <li className="post-left">
                                                                    <div className="Pimage-box" >
                                                                        <img src={data.image} alt="no-data" className="post-images" />
                                                                        <span className="numbers">{index + 1}</span>
                                                                    </div>
                                                                </li>
                                                                <li className="post-right">
                                                                    <p className="posts-content">{data.title}</p>
                                                                    <ul className="posts-bottom list-unstyled d-flex">
                                                                        <li className="dat-user">
                                                                            <span className="icnss">
                                                                                <i className="far fa-clock" />
                                                                            </span>
                                                                            {formatDate(data.date)}
                                                                        </li>
                                                                        {/* <li className="dat-user">
                                <span className="icnss">
                                  <i className="far fa-user" />
                                </span>
                                martha grim
                              </li> */}
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </NavLink>

                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 ps-lg-3">
                                        <div className="right-Data" >
                                            {allBlogs.map((data) => {
                                                const imageUrl = `data:image/jpg;base64,${data.image}`;
                                                return (
                                                    <NavLink className="linksdata-ins" to={`/Blogdata/${data.id}`} key={data._id}>
                                                        <div className="cardsBelow">
                                                            <div className="cardImgs">
                                                                <div className="blogs-topimages">
                                                                    <img src={data.image} alt="card" className="edit-CardIMG" />
                                                                </div>
                                                                {/* <img src="./images/blogs/blog-profile.png" alt="" className="user-image" /> */}
                                                            </div>
                                                            <div className="card-bdy">
                                                                <ul className="bdy-botom list-unstyled d-flex">
                                                                    <li className="date-user">
                                                                        <span className="iconss">
                                                                            <i className="far fa-clock" />
                                                                        </span>
                                                                        {formatDate(data.date)}
                                                                    </li>
                                                                    <li className="date-user">
                                                                        <span className="iconss">
                                                                            <i className="far fa-user" />
                                                                        </span>
                                                                        John Halliburton
                                                                    </li>
                                                                </ul>
                                                                <h2 className="Cbody-tiitle">{data.title}</h2>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                </div>

                <div className='joingroup-section'>
                    <div className='container'>
                        <div className='joingroup-content'>
                            <h3 className='join-title'>{t('landing.jointhezerohero')}</h3>
                            <p className='join-para'>{t('landing.beazerohero')}</p>
                            <p className='join-para'>Download Cero Hero and begin your journey towards a balanced, conscious, and rewarding green life</p>
                            {/* <ul className='join-userslist'>
                <li className='userslist-inside'><span>1M+</span>{t('landing.usersin')} <br /> {t('landing.India')}</li>
                <li className='userslist-inside'><span>500K</span>{t('landing.App')} <br />
                  {t('landing.Download')}</li>
              </ul> */}

                        </div>
                        <div className='become-cerohero'>
                            <ul className='become-lists'>
                                <li>
                                    <div className='app-qr-data'>
                                        <img className='qr-image' src='../images/footer-qr.png' alt='no-data' />
                                    </div>
                                </li>
                                <li>
                                    <h3 className='become-title'>{t('landing.becomeazerohero')}</h3>
                                    <p className='become-para'>{t('landing.dewnloadtheapp')}</p>
                                </li>
                                <li>
                                    <div className='app-download'>
                                        <NavLink to="https://play.google.com/store/apps/details?id=com.cerohero" target="_blank">
                                            <img className='download-image' src='../images/landing/app-store-1.png' alt='no-data' />

                                        </NavLink>
                                    </div>
                                    <div className='app-download'>
                                        <NavLink to="https://apps.apple.com/in/app/cero-hero/id6460891460" target="_blank">
                                            <img className='download-image' src='../images/landing/play-store-1.png' alt='no-data' />

                                        </NavLink>
                                    </div>
                                </li>
                            </ul>

                        </div>

                    </div>

                </div>
                <div className="subscribe-content">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Sustainability