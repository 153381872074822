import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'


const PersonsCalculator = () => {
  const [counterValue, setCounterValue] = useState(1);


  const incrementCounter = () => {
    if (counterValue < 9) { // Check if counterValue is less than 10
      setCounterValue(prevValue => prevValue + 1);
    }
  };

  const decrementCounter = () => {
    if (counterValue > 1) {
      setCounterValue(prevValue => prevValue - 1);
    }
  };
  const goBack = () => {
    window.history.back(); // This will navigate to the previous page in history
  };

  const personImages = Array.from({ length: counterValue }, (_, index) => (
    <img
      key={index}
      className="person-image"
      src={`./images/calculator/person-${(index % 2) + 1}.webp`}
      alt={`person-${(index % 2) + 1}`}
    />
  ));

  return (
    <>
      <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='doyouknow-content'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='doyouknow-content-left'>
                    <h3 className='doyouknow-value'>Your household</h3>
                    <p className='doyouknow-titlewhat'>How many people live in your house?</p>
                    <p className='doyouknow-para'>Identifying the size of your household is the first step towards accurately calculating your carbon footprint. Every activity, from energy use to waste production, impacts your environmental contribution. By knowing how many individuals share your space, we can provide tailored advice on reducing your household's carbon emissions. Let's embark on a journey towards a more sustainable lifestyle together.</p>

                    <ul className='previous-nextbtns  d-none d-md-flex'>
                      <li>
                        <NavLink onClick={goBack} className="prevbtn-data"><div className='previous-btn'>
                          <i class="fas fa-arrow-left"></i>
                        </div></NavLink>
                      </li>
                      <li>
                        <NavLink to={"/electricitycalculation"} onClick={()=>localStorage.setItem("selectedPersons",counterValue)} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='household-right'>
                    <img className="house-image" src="./images/calculator/house-image.webp" alt="house" />
                    <div className="counter">
                      <button className="counter__decrement" onClick={decrementCounter}>
                        &ndash;
                      </button>
                      <input
                        className="counter__input"
                        type="text"
                        value={counterValue}
                        readOnly
                        size="5"
                        name="counter"
                      />
                      <button className="counter__increment" onClick={incrementCounter}>
                        +
                      </button>
                    </div>
                    {/* Render person images */}
                    <div className="person-images-container person-data">
                      {personImages}
                    </div>
                  </div>

                </div>
                <ul className='previous-nextbtns previous-nextbtns2 d-md-none d-flex'>
                      <li>
                        <NavLink onClick={goBack} className="prevbtn-data"><div className='previous-btn'>
                          <i class="fas fa-arrow-left"></i>
                        </div></NavLink>
                      </li>
                      <li>
                        <NavLink to={"/electricitycalculation"} onClick={()=>localStorage.setItem("selectedPersons",counterValue)} className="next-btn">Next  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
                      </li>
                    </ul>
                
              </div>

            </div>

          </div>


        </div>
      </div>
    </>
  )
}

export default PersonsCalculator