import React, { useRef } from 'react'
import Header from '../Common/Header/Header'
import { Outlet } from 'react-router-dom'
import Footer from '../Common/Footer/Footer'

const Layout = () => {
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    console.log("===========",section1Ref);
  
    const scrollToSection = (sectionId) => {
      let ref;
      console.log(sectionId);
      switch (sectionId) {
        case 'section1':
          ref = section1Ref;
          break;
        case 'section2':
          ref = section2Ref;
          break;
        case 'section3':
          ref = section3Ref;
          break;
        default:
          return;
      }
  console.log(section1Ref);
      ref.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <Header />
            <Outlet />
            <Footer scrollToSection={scrollToSection} />
        </>
    )
}

export default Layout