import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import Axios from "axios";


const Ceroheroinaction = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = "https://blogapi.chakravuyha.com/api/user/subscribe";
        const body = {
            email: subscribemail,
        };
        const headerTypes = {
            "content-type": "application/json",
        };
        Axios.post(url, body, { headers: headerTypes })
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };
    return (
        <>
            <Helmet>
                <title>
                    sustainability_hub
                </title>
            </Helmet>
            <div className="cerohero-action">
                <div className="voice-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="voice-left">
                                    <span className="userstory-top">
                                        {t('ceroheroaction.userstories')}
                                    </span>
                                    <h3 className="voice-title">{t('ceroheroaction.voicesofthe')}</h3>
                                    <p className="voice-para">{t('ceroheroaction.meettheindividuals')}</p>
                                    {/* <p className="voice-para">{t('ceroheroaction.therijourneys')}</p> */}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="voice-right">
                                    <div className='voice-image-data'>
                                        <img className='voice-image' src='../images/action/voice.webp' alt='voices of the cerohero community, net-zero, carbon offset by individuals' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="storylist-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="inside-storylist">
                                    <h5 className="storylist-top">{t('ceroheroaction.stories')}</h5>
                                    <h3 className="storylist-title">{t('ceroheroaction.Finding My Green')}</h3>
                                    <p className="storylist-para">{t('ceroheroaction.[Name]s journey')}</p>
                                    <img src="../images/action/story-1.png" className="storylist-image" alt="no-data" />

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="inside-storylist">
                                    <h5 className="storylist-top">{t('ceroheroaction.stories')}</h5>
                                    <h3 className="storylist-title">{t('ceroheroaction.On the Road')}</h3>
                                    <p className="storylist-para">{t('ceroheroaction.Explore how')}</p>
                                    <img src="../images/action/story-2.png" className="storylist-image" alt="no-data" />

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="inside-storylist">
                                    <h5 className="storylist-top">{t('ceroheroaction.stories')}</h5>
                                    <h3 className="storylist-title"> {t('ceroheroaction.thezerowaste')}</h3>
                                    <p className="storylist-para">{t('ceroheroaction.Follow [Name] as')}</p>
                                    <img src="../images/action/story-3.png" className="storylist-image" alt="no-data" />

                                </div>

                            </div>
                        </div>
                        {/* <NavLink className="share-button">{t('ceroheroaction.Share Your Story')}</NavLink> */}
                    </div>

                </div>
                <div className="impactmetric-section">
                    <div className="container">
                        <div className="impactmeric-top">
                            <h3 className="impactmeric-title">{t('ceroheroaction.Impact Metrics')}</h3>
                            <p className="impactmeric-para">{t('ceroheroaction.Measurable Impact')}</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="carbonoffset-section">
                                    <div className="carbonoffset-inside d-flex align-items-center">
                                        <div className="carbonoffset-inside-image flex-shrink-0">
                                            <img className="carbonoffset-imagedata" src="./images/action/carbon-1.webp" alt="no-data" />
                                        </div>
                                        <div className="carbonoffset-body flex-grow-1 ms-3">
                                            <h3 className="carbonoffset-body-top">{t('ceroheroaction.Carbon Offset')}</h3>
                                            <h3 className="carbonoffset-body-title">{t('ceroheroaction.150 tons')}</h3>
                                            <p className="carbonoffset-body-para">{t('ceroheroaction.Together, weve')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carbonoffset-section">
                                    <div className="carbonoffset-inside d-flex align-items-center">
                                        <div className="carbonoffset-inside-image  flex-shrink-0">
                                            <img className="carbonoffset-imagedata carbonoffset-imagedata1" src="./images/action/carbon-2.webp" alt="no-data" />
                                        </div>
                                        <div className="carbonoffset-body flex-grow-1 ms-3">
                                            <h3 className="carbonoffset-body-top">{t('ceroheroaction.Waste Reduced')}</h3>
                                            <h3 className="carbonoffset-body-title">{t('ceroheroaction.150 kg of')}</h3>
                                            <p className="carbonoffset-body-para">{t('ceroheroaction.ZeroHeros have successfully')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="carbonoffset-section">
                                    <div className="card carbonoffset-inside carbonoffset-inside1" >
                                        <div className="card-body carbonoffset-body">
                                            <h3 className="carbonoffset-body-top">{t('ceroheroaction.Water Saved')}</h3>
                                            <h3 className="carbonoffset-body-title">1{t('ceroheroaction.15 liters')}</h3>
                                            <p className="carbonoffset-body-para carbonoffset-body-para1">{t('ceroheroaction.Our collective')}</p>
                                        </div>
                                        <div className="carbonoffset-inside-image carbonoffset-inside-image1 flex-shrink-0">
                                            <img className="carbonoffset-imagedata" src="./images/action/carbon-3.webp" alt="no-data" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/* <NavLink className="share-button">{t('ceroheroaction.See Full Impact')}</NavLink> */}

                    </div>

                </div>

                <div className="community-section">
                    <div className="container">
                        <div className="community-top">
                            <h3 className="community-title">{t('ceroheroaction.Community Highlights')}</h3>
                            <p className="community-para">{t('ceroheroaction.Spotlight on Our')}</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="community-highlits">
                                    <img src="../images/action/community-1.png" className="community-highlits-image" alt="no-data" />
                                    <h5 className="community-highlits-top">{t('ceroheroaction.Highlight')}</h5>
                                    <h3 className="community-highlits-title">{t('ceroheroaction.Schools Go Green')}</h3>
                                    <p className="community-highlits-para">{t('ceroheroaction.Discover how schools')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="community-highlits">
                                    <img src="../images/action/community-2.png" className="community-highlits-image" alt="no-data" />
                                    <h5 className="community-highlits-top">{t('ceroheroaction.Highlight')}</h5>
                                    <h3 className="community-highlits-title">{t('ceroheroaction.Local Heroes')}</h3>
                                    <p className="community-highlits-para">{t('ceroheroaction.Unveiling community-driven')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="community-highlits">
                                    <img src="../images/action/community-3.png" className="community-highlits-image" alt="no-data" />
                                    <h5 className="community-highlits-top">{t('ceroheroaction.Highlight')}</h5>
                                    <h3 className="community-highlits-title">{t('ceroheroaction.ZeroHero Partnerships')}</h3>
                                    <p className="community-highlits-para">{t('ceroheroaction.Explore our collaborations')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <NavLink className="community-highlits-button">{t('ceroheroaction.Get Involved')}</NavLink> */}
                    </div>

                </div>
                <div className="subscribe-content">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Ceroheroinaction