import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Countries from '../../../Store/Countries'
import Lottie from 'lottie-react';
import leaves from "./../../../Animations/Comp1.json";


const DoYouKnow = () => {
  const { name } = useParams();
  const [countriesData, setCountriesData] = useState([]);
  const [yearData, setYearData] = useState([]);


  useEffect(() => {
    try {
      let getInfo = async () => {
          let data ={
            answer:localStorage.getItem('answer'),
            description:localStorage.getItem('description'),
            countryName:localStorage.getItem('countryName'),
          }
          setCountriesData(data)
      }
      getInfo()
      let date = new Date();
      let previousYear = date.getFullYear() - 1;
      console.log(previousYear); 
      // This will output the previous year
      setYearData(previousYear)
      
    } catch (err) {

    }

  }, [])

  return (
    <>
      <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="/images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='doyouknow-content'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='doyouknow-content-left'>
                    <h3 className='doyouknow-title'>Do you know</h3>
                    <p className='doyouknow-titlewhat'>What {countriesData.countryName}’s carbon footprint was in {yearData}</p>
                    {countriesData && countriesData.answer ? (
                      <>
                        <h3 className='doyouknow-value'>{countriesData.answer}</h3>
                        {countriesData.description ? (
                          <p className='doyouknow-para'>{countriesData.description}</p>
                        ) : (
                          <p className='doyouknow-para'>Default description goes here...</p>
                        )}
                      </>
                    ) : (
                      <>
                        <h3 className='doyouknow-value'>1.91 CO2e metric tons</h3>
                        <p className='doyouknow-para'>Per capita carbon dioxide (CO₂) emissions in India have soared in recent decades, climbing from 0.39 metric tons in 1970 to a high of 1.91 metric tons in 2022. This was an increase of 5.5 percent in comparison to 2021 levels.</p>
                      </>
                    )}
                    <div className='d-none d-md-block'>
                    <ul className='previous-nextbtns '>
                      <li>
                        <NavLink to={"/countryselect"} className="prevbtn-data"><div className='previous-btn'>
                          <i class="fas fa-arrow-left"></i>
                        </div></NavLink>
                      </li>
                      <li>
                        <NavLink to={"/household"} className="next-btn">Next  <img className="arrow-image" src="/images/calculator/arrow.webp" alt="no-data" /></NavLink>
                      </li>
                    </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='doyouknow-content-right'>
                    {/* <img className="image-data" src="/images/calculator/doyouknow.webp" alt="no-data" /> */}
                    <Lottie
            className="image-data"
            animationData={leaves}
            loop={true}
            />
                  </div>
                </div>
                <div className='d-block d-md-none'> <ul className='previous-nextbtns previous-nextbtns1'>
                      <li>
                        <NavLink to={"/countryselect"} className="prevbtn-data"><div className='previous-btn'>
                          <i class="fas fa-arrow-left"></i>
                        </div></NavLink>
                      </li>
                      <li>
                        <NavLink to={"/household"} className="next-btn">Next  <img className="arrow-image" src="/images/calculator/arrow.webp" alt="no-data" /></NavLink>
                      </li>
                    </ul></div>
              </div>

            </div>

          </div>


        </div>
      </div>
    </>
  )
}

export default DoYouKnow