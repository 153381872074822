import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';

const Challenges = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = ApiNames.Subscribe_Newsletter;
        const body = {
            email: subscribemail,
        };
    
        Axios.post(url, body)
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };
    return (
        <>
         <Helmet>
                <title>
                    Challenges
                </title>
            </Helmet>
            <div className="challenges-main">
                <div className="challenges-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="challenges-inside-data">
                                    <div className="challenges-left">
                                        <h3 className="challenges-title">{t('challenges.Be a Part')}</h3>
                                        <p className="challenges-para">{t('challenges.Join hands with')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="challenges-right">
                                    <div className='challenges-image-data'>
                                        <img className='challenges-image' src='../images/challenges/challenges-image.webp' alt='no-data' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="challengeslists-section">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="challengeslists-highlits">
                                    <img src="../images/challenges/parking-1.png" className="challengeslists-highlits-image" alt="no-data" />
                                    <h5 className="challengeslists-highlits-top">{t('challenges.Challenge')}</h5>
                                    <h3 className="challengeslists-highlits-title">{t('challenges.Go Car-Free')}</h3>
                                    <p className="challengeslists-highlits-para">{t('challenges.Can you commit')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="challengeslists-highlits">
                                    <img src="../images/challenges/parking-2.png" className="challengeslists-highlits-image" alt="no-data" />
                                    <h5 className="challengeslists-highlits-top">{t('challenges.Challenge')}</h5>
                                    <h3 className="challengeslists-highlits-title">{t('challenges.Zero-Waste Week')}</h3>
                                    <p className="challengeslists-highlits-para">{t('challenges.Embrace a week')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="challengeslists-highlits">
                                    <img src="../images/challenges/parking-3.png" className="challengeslists-highlits-image" alt="no-data" />
                                    <h5 className="challengeslists-highlits-top">{t('challenges.Challenge')}</h5>
                                    <h3 className="challengeslists-highlits-title">{t('challenges.Plant-Based Pledge')}</h3>
                                    <p className="challengeslists-highlits-para">{t('challenges.Adopt a plant-based')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <NavLink className="challengeslists-highlits-button">{t('challenges.Join a Challenge')}</NavLink> */}
                    </div>

                </div>
                {/* <div className="celebarting-section">
                    <div className="container">
                        <div className="celebarting-top">
                            <h3 className="celebarting-title">{t('challenges.Celebrating our ZeroHeros')}</h3>
                            <p className="celebarting-para">{t('challenges.Meet the champions')}</p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="celebarting-highlits">
                                    <img src="../images/challenges/winner-1.webp" className="celebarting-highlits-image" alt="no-data" />
                                    <h3 className="celebarting-highlits-title">Name Name</h3>
                                    <p className="celebarting-highlits-para">{t('challenges.The Car-Free Conqueror')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="celebarting-highlits">
                                    <img src="../images/challenges/winner-2.webp" className="celebarting-highlits-image" alt="no-data" />
                                    <h3 className="celebarting-highlits-title">Name Name</h3>
                                    <p className="celebarting-highlits-para">{t('challenges.The Zero-Waste Warrior')}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="celebarting-highlits">
                                    <img src="../images/challenges/winner-3.webp" className="celebarting-highlits-image" alt="no-data" />
                                    <h3 className="celebarting-highlits-title">Name Name</h3>
                                    <p className="celebarting-highlits-para">{t('challenges.The Plant-Based Pioneer')}</p>
                                </div>
                            </div>
                        </div>
                        <NavLink className="celebarting-highlits-button">{t('challenges.View All Winners')}</NavLink>
                    </div>

                </div> */}
                <div className="participation-section">
                    <div className="container">
                        <div className="participation-top">
                            <h3 className="participation-title">{t('challenges.How to Participate')}</h3>
                            <p className="participation-para">{t('challenges.Joining the ZeroHero')}</p>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="inside-left">
                                    <div className="participation-left">
                                        <h3 className="left-title">{t('challenges.Step')} 1</h3>
                                        <h3 className="left-subtitle">{t('challenges.Choose Your Challenge')}</h3>
                                        <p className="left-para">{t('challenges.Explore ongoing')}</p>

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="participation-right">
                                    <div className="right-inside">
                                        <img className="right-image" src="../images/challenges/participation-1.webp" alt="no-data" />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row flex-wrap-reverse justify-content-center">

                            <div className="col-md-4">
                                <div className="participation-right">
                                    <div className="right-inside">
                                        <img className="right-image" src="../images/challenges/participation-2.webp" alt="no-data" />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="inside-left">
                                    <div className="participation-left">
                                        <h3 className="left-title">{t('challenges.Step')} 2</h3>
                                        <h3 className="left-subtitle">{t('challenges.Take Action')}</h3>
                                        <p className="left-para">{t('challenges.Follow the guidelines')}</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="inside-left">
                                    <div className="participation-left">
                                        <h3 className="left-title">{t('challenges.Step')} 3</h3>
                                        <h3 className="left-subtitle">{t('challenges.Track and Share')}</h3>
                                        <p className="left-para">{t('challenges.Use the ZeroHero')}</p>

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="participation-right">
                                    <div className="right-inside">
                                        <img className="right-image" src="../images/challenges/participation-3.webp" alt="no-data" />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row flex-wrap-reverse justify-content-center">

                            <div className="col-md-4">
                                <div className="participation-right">
                                    <div className="right-inside">
                                        <img className="right-image" src="../images/challenges/participation-4.webp" alt="no-data" />
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="inside-left">
                                    <div className="participation-left">
                                        <h3 className="left-title">{t('challenges.Step')} 4</h3>
                                        <h3 className="left-subtitle">{t('challenges.Celebrate and Inspire')}</h3>
                                        <p className="left-para">{t('challenges.Every action')}</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <div className="subscribe-content">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )

}
export default Challenges 