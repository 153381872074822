import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';
const Partners = () => {
    const { t } = useTranslation();
    const [subscribemail, setsubscribemail] = useState("");
    const [subscribemailErr, setsubscribemailErr] = useState("");
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "subscribemail") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setsubscribemailErr("");
            } else {
                setsubscribemailErr("please enter correct email");
            }
            setsubscribemail(event.target.value);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (subscribemail === "" || subscribemailErr !== "") {
            alert("Please fill input fields");
            return;
        } // api call
        const url = ApiNames.Subscribe_Newsletter;
        const body = {
            email: subscribemail,
        };
    
        Axios.post(url, body)
            .then((res) => {
                if (res.data.success === true) {
                    setsubscribemail("");
                    toastr.success(res.data.message);
                } else {
                    setsubscribemail("");
                    toastr.error(res.data.message);
                }
            })
            .catch((error) => {
                toastr.error(error.response?.data?.message);
            });
    };
    return (
        <>
            <Helmet>
                <title>
                    Partners
                </title>
            </Helmet>
            <div className="partners-data">
                <div className="partners-herosection">
                    <div className="container">
                        <div className='partners-hero'>
                            <h3 className='partners-title'>{t('partners.United for')} <br />
                                {t('partners.Our Esteemed')}</h3>
                            <p className='partners-subtitle'>{t('partners.Joining Forces')}</p>
                        </div>

                    </div>
                </div>
                <div className="ourecopartners-section">
                    <div className="container">
                        <h3 className="ourecopartners-toptitle">{t('partners.Our Eco-Partners')}</h3>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="ourecopartners-right">
                                    <div className='ourecopartners-image'>
                                    <img className="partners-images" src="../images/landing/URBAN.webp" alt="no-data" />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="ourecopartners-left">
                                    <div className='ourecopartners-hero'>
                                        <h3 className='ourecopartners-left-title'>Urban Tech Helsinki</h3>
                                        <p className='ourecopartners-left-subtitle'>Urban Tech Helsinki fosters solutions that transform cities into smarter, greener, and more livable spaces by providing access to research, advanced facilities, and a vibrant network of innovators.
                                        In collaboration with Cero Hero, Urban Tech Helsinki is integrating emission tracking and climate literacy tools into urban systems. Together, we are shaping the future of urban living and empowering communities to reduce their carbon footprints.</p>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="ourecopartners-left">
                                    <div className='ourecopartners-hero'>
                                        <h3 className='ourecopartners-left-title'>{t('partners.GreenLeaf Innovations')}</h3>
                                        <p className='ourecopartners-left-subtitle'>{t('partners.Revolutionizing Renewable')}</p>
                                      
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="ourecopartners-right">
                                    <div className='ourecopartners-image'>
                                        <img className="partners-images" src="../images/landing/wavpe-image.webp" alt="no-data" />

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row flex-wrap-reverse justify-content-center">
                            <div className="col-md-6">
                                <div className="ourecopartners-right">
                                    <div className='ourecopartners-image'>
                                    <img className="partners-images" src="../images/landing/gm-image.webp" alt="no-data" />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="ourecopartners-left">
                                    <div className='ourecopartners-hero'>
                                        <h3 className='ourecopartners-left-title'>{t('partners.AquaPura')}</h3>
                                        <p className='ourecopartners-left-subtitle'>{t('partners.Pioneering Sustainable')}</p>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="ourecopartners-left">
                                    <div className='ourecopartners-hero'>
                                        <h3 className='ourecopartners-left-title'>{t('partners.EcoWear')}</h3>
                                        <p className='ourecopartners-left-subtitle'>{t('partners.Fashion That Cares')}</p>

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="ourecopartners-right">
                                    <div className='ourecopartners-image'>
                                    <img className="partners-images" src="../images/landing/devotion-image.webp" alt="no-data" />

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row flex-wrap-reverse justify-content-center">
                           
                            <div className="col-md-6">
                                <div className="ourecopartners-right">
                                    <div className='ourecopartners-image'>
                                    <img className="partners-images" src="../images/landing/Justright-logo.svg" alt="no-data" />

                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="ourecopartners-left">
                                    <div className='ourecopartners-hero'>
                                        <h3 className='ourecopartners-left-title'>Bridging Aesthetics and Eco-conscious Innovation</h3>
                                        <p className='ourecopartners-left-subtitle'>Just Right a shining example of eco-conscious innovation, merging aesthetics with sustainability. Specializing in signage and LED solutions, they use 95% reusable plastics and sustainable processes to minimize environmental impact. This partnership enriches the marketplace with products that embody functionality, beauty, and environmental stewardship. Together, Just Right and Cero Hero Marketplace champion a future where commerce aligns with conservation, setting new standards for sustainable living.</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                   

                    </div>

                </div>

                <div className="joinmoment-section">
                    <div className="container">
                        <div className='partners-hero'>
                            <h3 className='partners-title'>{t('partners.Join the Movement')}</h3>
                            <p className='partners-subtitle'>{t('partners.Are you a business')}</p>
                            <NavLink className="deepdives-button" to={"/contactus"}>{t('partners.Contact us')}</NavLink>
                        </div>

                    </div>
                </div>
                <div className="subscribe-content">
                    <div className="container">
                        <div className="inside-subscribe">
                            <h3 className="subscrib-title">{t('landing.subscribetoour')} <span>{t('landing.Newsletter')}</span></h3>
                            <p className="subscrib-para">
                                {t('landing.getourfortnightly')} <NavLink to="/privacypolicy" className="privacy-data">{t('landing.privacypolicy')}</NavLink>
                            </p>
                        </div>
                        <form className="subscribe-form" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className=" col-md-6">
                                    <div className>
                                        <input
                                            type="email"
                                            className="form-control email-data"
                                            id="email"
                                            value={subscribemail}
                                            placeholder="Your email address"
                                            name="subscribemail"
                                            onChange={handleChange}
                                        />
                                        {subscribemailErr ? (
                                            <p className="text-danger">{subscribemailErr}</p>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <button
                                        type="submit"
                                        className="btn btn-primary subscribe-btn"
                                    >
                                        {t('landing.Go')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </>
    )
}
export default Partners