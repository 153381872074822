import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { DotLoader } from "react-spinners";
import ApiNames from "../../Constants/ApiNames";
import Axios from './../../Utils/AxiosConfig';


const Blogdata = () => {
  const { id } = useParams();
  const [userdata, setuserdata] = useState("");
  const [updateformate, setupdateformate] = useState("");
  const [dateformate, setdateformate] = useState("");
  const [timeformate, settimeformate] = useState("");
  const [blogData, setblogData] = useState([]);
  const [html, setHTML] = useState({ __html: "" });
  let [loading, setLoading] = useState(false);
  function formatDate(dateString) {
    const date = new Date(dateString);
    let info = format(date, "yyyy-MM-dd");
    setdateformate(info);
  }
  function formatDate1(dateString) {
    const date = new Date(dateString);
    let info = format(date, "yyyy-MM-dd");
    setupdateformate(info);
  }
  const override = {
    display: "block",
    margin: "20px auto",
    borderColor: "#025F5C",
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    setLoading(true);
    // api call
    const url = ApiNames.Cerohero_Blogdata + id;
    const body = {
      _id: userdata._id,
    };
    const headerTypes = {
      "content-type": "application/json",
    };
    Axios.get(url, body)
      .then((res) => {
        // console.log(res.data[0]);
        setLoading(false)
        setblogData(res.data.post);
        formatDate(res.data.post.date);
        formatDate1(res.data.post.updatedOn);
      
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Blog Data</title>
      </Helmet>
      <div className="blog-sub-page">
        <div className="container">
          <div className="blog-dBelow">
            {loading ? <DotLoader
              color={"#025F5C"}
              cssOverride={override}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> :
              <div className="row justify-content-center">

                <div className="col-md-9 ps-lg-3">
                  <div className="B-right-Data">
                    <div className="B-cardsBelow">
                      <div className="B-card-bdy">
                        <h2 className="B-Cbody-tiitle">
                          {blogData.title}
                        </h2>
                        <ul className="B-bdy-botom list-unstyled d-flex">
                          <li className="date-LIST">
                            {dateformate}
                            {/* {formatDate(blogData.date)} */}
                          </li>
                          <li className="date-LIST">{timeformate}</li>
                       
                        </ul>
                      </div>
                      <div className="B-cardImgs">
                        <img
                          src={blogData.image}
                          alt="card"
                          className="B-edit-CardIMG"
                        />
                      </div>
                    </div>
                    {/* <h2 className="what-nft">{blogData.title}</h2> */}
                    {/* <p className="nft-conttent">{blogData.description}</p> */}
                    <div
                      dangerouslySetInnerHTML={{ __html: blogData.description }}
                    ></div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default Blogdata;