import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import ApiNames from '../../Constants/ApiNames';
import Axios from './../../Utils/AxiosConfig';


const Contactus = () => {
    const [yourname, setYourname] = useState("");
    const [yournameErr, setYournameErr] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setemailErr] = useState("");
    const [company, setCompany] = useState("");
    const [companyErr, setCompanyErr] = useState("");
    const [Phone, setPhone] = useState("");
    const [phoneErr, setphoneErr] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionErr, setDescriptionErr] = useState("");
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const handleChange = (event) => {
        // console.log("---->", event.target.name, event.target.value);
        if (event.target.name === "yourname") {
            if (event.target.value.length <= 2) {
                setYournameErr("name must be min 3 letters");
            } else {
                setYournameErr("");
            }

            setYourname(event.target.value);
        } else if (event.target.name === "email") {
            let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
            if (reg.test(event.target.value)) {
                setemailErr("");
            } else {
                setemailErr("please enter correct email");
            }
            setEmail(event.target.value);
        } else if (event.target.name === "Phone") {
            let reg = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
            if (reg.test(event.target.value)) {
                setphoneErr("");
            } else {
                setphoneErr("please enter correct Phone Number");
            }
            setPhone(event.target.value);
        } else if (event.target.name === "company") {
            if (event.target.value.length <= 2) {
                setCompanyErr("company name must be min 3 letters");
            } else {
                setCompanyErr("");
            }

            setCompany(event.target.value);
        } else if (event.target.name === "description") {
            if (event.target.value.length <= 9) {
                setDescriptionErr("description must be min 10 letters");
            } else {
                setDescriptionErr("");
            }

            setDescription(event.target.value);
        }

    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            yourname === "" ||
            yournameErr !== "" ||
            email === "" ||
            emailErr !== "" ||
            Phone === "" ||
            phoneErr !== "" ||
            company === "" ||
            companyErr !== "" ||
            description === "" ||
            descriptionErr !== ""
        ) {
            alert("Please fill all input fields");
            return;
        }
        // api call

        const url = ApiNames.Cerohero_Contactus;
        const body = {
            name: yourname,
            email: email,
            phone: Phone,
            company: company,
            description: description,

        };
        Axios.post(url, body)
            .then((res) => {
                console.log(res);

                // Clear input fields and error states
                setYourname("");
                setEmail("");

                setPhone("");

                setCompany("");
                setDescription("");
                toastr.success(res.data.message);


            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            <Helmet>
                <title>
                    Contact Us
                </title>
            </Helmet>

            <div className="contactus-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                           <div className="contactus-mainleft">
                           <div className="contactus-left">
                                <h3 className="contactus-title">Join Us in Shaping a Sustainable Future
                                </h3>
                                <p className="contactus-discover">Cero Hero is dedicated to leading the charge towards a more sustainable and eco-friendly world. Our mission is centered around leveraging innovative technologies and strategies to reduce carbon footprints and promote environmental stewardship across communities and industries.
                                </p>
                                <h3 className="contactus-matter">We're Here to Listen and Collaborate</h3>
                                <p className="contactus-subtitle">Your thoughts, inquiries, and suggestions are crucial to our mission as we aim to make sustainability both impactful and accessible to everyone. Whether you're keen to discover more about our initiatives, explore potential partnerships, or offer your unique perspectives, we're genuinely interested in engaging with you.</p>
                            </div>
                           </div>

                        </div>
                        <div className="col-md-6">
                            <div className="contactus-right">
                                <h3 className="get-connet-title">Get Connected</h3>
                                <p className="get-connet-subtitle">Fill out our contact form with your details and message, and one of our team members will get back to you as soon as possible. Your journey towards making a positive environmental impact starts here. </p>
                            <form className="conatctform" onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label contactform-label">
                                                    Your Name *
                                                </label>
                                                <input
                                                    type="text"
                                                    name="yourname"
                                                    value={yourname}
                                                    className="form-control contactform-input"
                                                    placeholder="Your Name"
                                                    onChange={handleChange}

                                                />
                                                {yournameErr ? (
                                                    <p className="text-danger">{yournameErr}</p>
                                                ) : null}

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label contactform-label">
                                                    Email *
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control contactform-input"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={email}
                                                    onChange={handleChange}
                                                />
                                                {emailErr ? (
                                                    <p className="text-danger">{emailErr}</p>
                                                ) : null}

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label contactform-label">
                                                    Company *
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control contactform-input"
                                                    placeholder="Company"
                                                    value={company}
                                                    name="company"
                                                    onChange={handleChange}
                                                />
                                                {companyErr ? (
                                                    <p className="text-danger">{companyErr}</p>
                                                ) : null}

                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label contactform-label">
                                                    Phone *
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="form-control contactform-input"
                                                    placeholder="Phone "
                                                    name="Phone"
                                                    value={Phone}
                                                    onChange={handleChange}
                                                />
                                                {phoneErr ? (
                                                    <p className="text-danger">{phoneErr}</p>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="floatingTextarea2" className="contactform-label">Description *</label>

                                                <textarea
                                                    className="form-control contactform-input"
                                                    placeholder="Leave a Description here"
                                                    id="floatingTextarea2"
                                                    style={{ height: 100 }}
                                                    defaultValue={""}
                                                    name="description"
                                                    onChange={handleChange}
                                                    value={description}
                                                />
                                                {descriptionErr ? (
                                                    <p className="text-danger">{descriptionErr}</p>
                                                ) : null}
                                            </div>

                                            <button type="submit" className="btn btn-primary submit-btn-c">
                                                Submit
                                            </button>
                                        </form>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}

export default Contactus;