import React from "react";
import { NavLink } from "react-router-dom";

const LetsStartCalculator = () => {
    const resetStorage =() =>{
        localStorage.clear()
    }
    return (
        <>
            <div className="calculations-content">
                <div className="container">
                    <ul className="calcularor-navbar">
                        <li>
                            <NavLink to={"/"} ><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
                            </div></NavLink>
                        </li>
                    </ul>

                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="letsstart-content">
                                <div className="letsstart-left">
                                    <h3 className="letststart-title">Answer a few questions and we’ll calculate your carbon footprint</h3>
                                    <ul className="carboncredits-ul">
                                        <li className="carboncredits-li">
                                            <img className="carboncredits-image" src="./images/calculator/heart.webp" alt="no-data" /> <p className="carboncredits-para">Find out how your actions impact the environment</p>
                                        </li>
                                        <li className="carboncredits-li">
                                            <img className="carboncredits-image" src="./images/calculator/heart.webp" alt="no-data" /> <p className="carboncredits-para">Be rewarded for choosing sustainable actions </p>
                                        </li>
                                        <li className="carboncredits-li">
                                            <img className="carboncredits-image" src="./images/calculator/heart.webp" alt="no-data" /> <p className="carboncredits-para">Contribute to a greener future</p>
                                        </li>
                                        <li className="carboncredits-li">
                                            <p className="carboncredits-para carboncredits-para1">Takes less than 5 minutes to complete</p>
                                        </li>
                                    </ul>
                                    <div className="d-none d-md-block">
                                    <NavLink  to={"/countryselect"} onClick={resetStorage} className="getstart-btn ">Get Started  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="letsstart-content">
                                <div className="letsstart-right">
                                <img className="image-data" src="./images/calculator/letsstart-right.webp" alt="no-data" />
                                </div>

                            </div>
                            <div className="d-block d-md-none"><NavLink  to={"/countryselect"} onClick={resetStorage} className="getstart-btn getstart-btn1">Get Started  <img className="arrow-image" src="./images/calculator/arrow.webp" alt="no-data" /></NavLink></div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default LetsStartCalculator;